import React, { useEffect, useState } from "react";
import { Table, Row, Col, Image, Tag, Modal, message, Divider } from "antd";
import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  SearchOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { numberFormatter } from "../../../utility/Formatter";
import { FlexCol, FlexRow } from "../../../components/Container/Container";
import Text from "../../../components/Text/Text";
import { STATUS_COLOR_MAPPING } from "../../../definitions/product";
import color from "../../../resource/color";
import image from "../../../resource/image";
import { CardContainer, OuterCardContainer } from "../../../components/Card/CardContainer";
import { checkPermission } from "../../../components/Permission/Permission";
import { useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import { roleAtom } from "../../../store/RoleAtom";
import Input from "../../../components/Input/Input";
import Button from "../../../components/Button/Button";
import icon from "../../../resource/icon";
import TableContainer from "../../../components/Table/TableContainer";
import { GreyTableContainer } from "../../../components/Table/GreyTable";
import dayjs from "dayjs";
import { ActionBtn } from "../../../components/Button/TableActionButton";
import TablePagination from "../../../components/Table/TablePagination";
import { useEffectOnce } from "react-use";
import PageTitleNested from "../../../components/PageTitle/PageTitleNested";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import Tabs from "../../../components/AntdTabs/AntdTabs";
import CreateEdit from "./SharedComponent/CreateEdit";

const CreateThreshold: React.FC = () => {
  const userProfile = JSON.parse(localStorage.getItem("profile")!);
  const { firstname, lastname } = userProfile;
  const company = JSON.parse(localStorage.getItem("company")!);

  const pageName = "เพิ่มกลุ่มตรวจจับร้านค้า";

  return (
    <>
      <OuterCardContainer>
        <CardContainer cutBottom>
          <PageTitleNested
            title={pageName}
            customBreadCrumb={
              <BreadCrumb
                data={[
                  { text: "เกณฑ์ตรวจจับ", path: "/DetectOrderPage/threshold" },
                  {
                    text: pageName,
                    path: window.location.pathname,
                  },
                ]}
              />
            }
          />
        </CardContainer>
        <Divider />
        <CardContainer cutTop>
          <CreateEdit />
        </CardContainer>
      </OuterCardContainer>
    </>
  );
};

export default CreateThreshold;
