import {
  DownOutlined,
  InfoCircleFilled,
  InfoOutlined,
  SearchOutlined,
  StarFilled,
  StarOutlined,
  UnorderedListOutlined,
  UpOutlined,
} from "@ant-design/icons";
import React, { ReactNode, useEffect, useState } from "react";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import Tabs, { PrimaryTabsContainer } from "../../../components/AntdTabs/AntdTabs";
import { CardContainer } from "../../../components/Card/CardContainer";
import PageTitleNested from "../../../components/PageTitle/PageTitleNested";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "moment/locale/th";
import Text from "../../../components/Text/Text";
import { Button as AntdButton, Col, Input, Modal, Row, Table, Tag } from "antd";
import image from "../../../resource/image";
import styled from "styled-components";
import { Campaign, CampaignProgram } from "../../../entities/CampaignEntity";
import color from "../../../resource/color";
import { historyCampaign, historyCampaignDetail } from "../../../datasource/CampaignDatasource";
import {
  CampaignProgramType,
  HistoryCampaignType,
  campaignProgramConditionLabel,
  campaignProgramTypeLabel,
  dealerTypeName,
  historyCampaignTypeLabel,
} from "../../../definitions/campaign";
import Select from "../../../components/Select/Select";
import { useEffectOnce } from "react-use";
import { zoneDatasource } from "../../../datasource/ZoneDatasource";
import { numberFormatter } from "../../../utility/Formatter";
import { FlexCol, FlexRow } from "../../../components/Container/Container";
import dayjs from "dayjs";
import TableContainer from "../../../components/Table/TableContainer";
import GreyTable from "../../../components/Table/GreyTable";
import { mapSortingKey } from "../../../definitions/tableFilter";
import { getPartnerDealer, getPartnerSubdealer } from "../../../datasource/PartnerDatasource";
import axios, { AxiosRequestConfig } from "axios";
import { BASE_URL } from "../../../config/develop-config";
import Button from "../../../components/Button/Button";
import icon from "../../../resource/icon";

const Container = styled.div`
  margin: 16px 0px 10px 0px;
`;

const Desc = ({
  label,
  value,
  labelIcon,
}: {
  label: string;
  value: ReactNode;
  labelIcon?: ReactNode;
}) => {
  return (
    <Col span={12}>
      <Row align='middle' style={{ padding: "8px 0px" }}>
        <Col xl={7} sm={8}>
          <Text level={5} color='Text3'>
            {labelIcon && <>{labelIcon}&nbsp;</>}
            {label}
          </Text>
        </Col>
        <Col xl={17} sm={16}>
          <Text level={5} color='Text1'>
            {value || "-"}
          </Text>
        </Col>
      </Row>
    </Col>
  );
};

const TrackingItem = ({
  title,
  number,
  unit,
  icon,
}: {
  title: ReactNode;
  number?: number;
  unit: string;
  icon: any;
}) => {
  return (
    <div
      style={{ padding: 16, background: "#FAFAFA", border: "1px solid #E5EAEE", borderRadius: 4 }}
    >
      <Text fontWeight={700}>
        {/* <StarOutlined style={{ color: "#FFB761" }} /> */}
        <img src={icon} style={{ width: 18, height: 18 }} />
        &nbsp;
        {title}
      </Text>
      <br />
      <Text level={2} fontWeight={700}>
        {number ? numberFormatter(number, 2) : "0.00"}
      </Text>
      &nbsp;&nbsp;<Text fontWeight={700}>{unit}</Text>
    </div>
  );
};

export const CampaignPointHistory: React.FC = () => {
  const userProfile = JSON.parse(localStorage.getItem("profile")!);
  const { company } = userProfile;

  const { pathname } = window.location;
  const pathSplit = pathname.split("/") as Array<string>;
  const id = pathSplit[4];

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [campaignData, setCampaignData] = useState<Campaign>();

  const [loadingProgram, setLoadingProgram] = useState(false);
  const [programData, setProgramData] = useState<CampaignProgram[]>();
  const [programDataCount, setProgramDataCount] = useState<number>();

  const [zone, setZone] = useState();
  const [zoneData, setZoneData] = useState<{ label: string; value: string; key: string }[]>([]);

  const [activeProgram, setActiveProgram] = useState<CampaignProgram>();
  const [currentTab, setCurrentTab] = useState<HistoryCampaignType>(
    HistoryCampaignType.WAIT_APPROVE,
  );

  const [search, setSearch] = useState("");

  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState();

  const [page, setPage] = useState(1);
  const resetPage = () => setPage(1);

  const [modalParner, setModalParner] = useState<boolean>(false);
  const [dataParner, setDataParner] = useState<any>();

  useEffectOnce(() => {
    if (zoneData.length <= 0) {
      getZoneByCompany();
    }
  });
  const getZoneByCompany = async () => {
    const res = await zoneDatasource.getAllZoneByCompany(company);
    const data = res.map((item: any) => {
      return {
        label: item.zoneName,
        value: item.zoneName,
        key: item.zoneId,
      };
    });
    setZoneData(data);
  };

  useEffectOnce(() => {
    fetchCampaign();
  });

  useEffect(() => {
    if (!loadingProgram && activeProgram) fetchProgramData();
  }, [activeProgram, currentTab, page, zone, search, sortField, sortOrder]);

  const fetchCampaign = async () => {
    setLoading(true);
    await historyCampaign(id)
      .then((res) => {
        setCampaignData(res);
        console.log(res);
        if (res?.campaignProgram && res?.campaignProgram[0]) {
          setActiveProgram(res?.campaignProgram[0]);
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchProgramData = async () => {
    console.log("FETCH START");
    setLoadingProgram(true);
    await historyCampaignDetail({
      campaignProgramId: activeProgram?.campaignProgramId || "",
      historyCampaignType: currentTab,
      page,
      take: 8,
      search,
      zone,
      sortBy: sortField,
      sortDirection: sortOrder,
    })
      .then((res) => {
        console.log("FETCH SUCCESS: ", res);
        setProgramData(res?.data);
        setProgramDataCount(res?.count);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoadingProgram(false);
      });

    // setLoadingProgram(false);
  };

  const onDownloadExcel = async () => {
    const headers = { "Content-Type": "blob" };
    const config: AxiosRequestConfig = {
      method: "POST",
      url: `${BASE_URL}/crm/campaign/excel-report-history-campaign`,
      responseType: "arraybuffer",
      headers,
      data: {
        campaignId: id,
      },
    };
    const response = await axios(config);

    const file = new Blob([response.data], {
      type: "application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = window.URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `report-point-history-${id}-${new Date().toISOString().split("T")[0]}.xlsx`,
    );

    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  };

  const PageTitle = () => {
    return (
      <PageTitleNested
        title={"ประวัติรายการแต้ม"}
        showBack
        customBreadCrumb={
          <BreadCrumb
            data={[
              { text: "รายการแคมเปญ", path: "/crm/campaignPoint" },
              {
                text: "ประวัติรายการแต้ม",
                path: window.location.pathname,
              },
            ]}
          />
        }
        extra={
          <Button
            title='ดาวน์โหลดประวัติรายการแต้ม'
            icon={<img src={icon.downloadFile} style={{ width: 14, height: 18 }} />}
            onClick={onDownloadExcel}
            loading={loading}
            typeButton='primary'
          />
        }
      />
    );
  };

  const details = [
    {
      label: "รหัส | ชื่อแคมเปญ",
      value: campaignData?.campaignCode + " | " + campaignData?.campaignName,
    },
    {
      label: "ระยะเวลาแคมเปญ",
      value:
        `${dayjs(campaignData?.startDate).format("DD/MM/YYYY")} - ${dayjs(
          campaignData?.endDate,
        ).format("DD/MM/YYYY")} ` +
        (campaignData?.daysPassed && campaignData?.daysPassed > 0
          ? ` (ผ่านมาแล้ว ${campaignData?.daysPassed} วัน)`
          : ""),
    },
    {
      label: "เป้าหมายในแคมเปญ",
      value: "เพื่อ Rebate",
    },
    {
      label: "ร้านค้าทั้งหมด",
      value: (campaignData as any)?.countShopAll + " ร้าน",
    },
  ];

  const details2 = [
    {
      label: "รูปแบบการให้แต้มร้านค้า",
      value: activeProgram?.campaignProgramType
        ? campaignProgramTypeLabel[activeProgram.campaignProgramType]
        : "",
    },
    {
      label: "เงื่อนไขการได้รับแต้ม",
      value: activeProgram?.campaignProgramCondition
        ? campaignProgramConditionLabel[activeProgram.campaignProgramCondition]
        : "",
    },
  ];

  const tabsItems = [
    {
      key: HistoryCampaignType.WAIT_APPROVE,
      label: "รอรับแต้ม",
    },
    {
      key: HistoryCampaignType.APPROVE,
      label: "ได้รับแต้ม",
    },
    {
      key: HistoryCampaignType.CANCEL,
      label: "ยกเลิกแต้ม",
    },
  ];

  const columnWidth = [160, 140, 240, 110, 120, 100, 90, 120];

  const programColumn = [
    {
      title: "เลขที่สั่งขาย (SO)",
      dataIndex: "soNo",
      key: "soNo",
      width: columnWidth[0],
      hideForSd: true,
      render: (value: any, row: CampaignProgram, index: number) => {
        return (
          <FlexCol>
            <Text>{value || "กำลังอัปเดท"}</Text>
            <Text level={6} color='Text3'>
              {dayjs(row?.createAt).format("DD/MM/YYYY, HH:mm")}
            </Text>
          </FlexCol>
        );
      },
    },
    {
      title: "เลขที่ส่งเอกสาร",
      dataIndex: "orderPointCode",
      key: "orderPointCode",
      width: columnWidth[0],
      hideForDl: true,
      render: (value: any, row: CampaignProgram, index: number) => {
        return (
          <FlexCol>
            <Text>{value || "กำลังอัปเดท"}</Text>
            <Text level={6} color='Text3'>
              {dayjs(row?.dateDelivery).format("DD/MM/YYYY, HH:mm")}
            </Text>
          </FlexCol>
        );
      },
    },
    {
      title: "เลขที่ใบขน (PDO)",
      dataIndex: "pdoNo",
      key: "pdoNo",
      hideForSd: true,
      width: columnWidth[1],
      render: (value: any, row: CampaignProgram, index: number) => {
        return (
          <FlexCol>
            <Text>{value || "-"}</Text>
          </FlexCol>
        );
      },
    },
    {
      title: "ชื่อร้านค้า",
      dataIndex: "shopName",
      key: "shopName",
      // width: columnWidth[2],
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <Text>{value}</Text>
              <br />
              <Text level={6} color='Text3'>
                {row?.shopNo}&nbsp;•&nbsp;{dealerTypeName[row?.userType]}&nbsp;•&nbsp;
                {row?.shopZone}&nbsp;
                <InfoCircleFilled
                  style={{ color: color.Text3, fontSize: 12, cursor: "pointer" }}
                  onClick={() => {
                    setModalParner(!modalParner);
                    getParner(row.shopId, row.userType);
                  }}
                />
              </Text>
            </>
          ),
        };
      },
    },
    {
      title: "จำนวนสินค้า",
      dataIndex: "countProduct",
      key: "countProduct",
      width: columnWidth[3],
      sorter: true,
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <Text>{value}</Text>
              <br />
              <Text level={6} color='Text3'>
                รายการ
              </Text>
            </>
          ),
        };
      },
    },
    {
      title: "ปริมาณที่สั่งซื้อ",
      dataIndex: "sumAmount",
      key: "sumAmount",
      width: columnWidth[4],
      sorter: true,
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <Text>{value}</Text>
              <br />
              <Text level={6} color='Text3'>
                {row?.unitName}
              </Text>
            </>
          ),
        };
      },
    },
    {
      title: "จำนวนตัน",
      dataIndex: "sumQuantity",
      key: "sumQuantity",
      width: columnWidth[5],
      sorter: true,
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <Text>{value}</Text>
              <br />
              <Text level={6} color='Text3'>
                ตัน
              </Text>
            </>
          ),
        };
      },
    },
    {
      title: "สถานะ",
      dataIndex: "status",
      key: "status",
      width: columnWidth[6],
      render: (value: HistoryCampaignType) => {
        const colorMap = {
          DRAFT: color.warning,
          WAIT_APPROVE: color.warning,
          REJECT: color.error,
          APPROVE: color.success,
          CANCEL: color.error,
        };
        return <Tag color={colorMap[value]}>{historyCampaignTypeLabel[value]}</Tag>;
      },
    },
    {
      title: "แต้มที่ได้รับ",
      dataIndex: "sumPoint",
      key: "sumPoint",
      width: columnWidth[7],
      sorter: true,
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <Text>{value ? numberFormatter(value, 2) : "กำลังคำนวน"}</Text>
              <br />
              <Text level={6} color='Text3'>
                แต้ม
              </Text>
            </>
          ),
        };
      },
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      width: 48,
      hideForDl: true,
      render: (value: any, row: any) => {
        return (
          <div
            className='btn btn-icon btn-light btn-hover-primary btn-sm'
            onClick={() => navigate("/crm/approvePointList/" + row.orderPointId)}
          >
            <span
              className='svg-icon svg-icon-primary svg-icon-2x'
              style={{ color: color["primary"] }}
            >
              <UnorderedListOutlined style={{ width: 16, height: 16, fontWeight: 700 }} />
            </span>
          </div>
        );
      },
    },
  ].filter(({ hideForDl, hideForSd }) => {
    if (activeProgram?.campaignProgramType === CampaignProgramType.ONLY_DEALER) {
      return !hideForDl;
    } else {
      return !hideForSd;
    }
  });

  const renderProduct = (value: any, row: any, index: number) => {
    return {
      children: (
        <>
          <FlexRow align='center'>
            <img
              src={row?.productImage}
              style={{ width: 50, height: 50, marginRight: 12, objectFit: "contain" }}
            />
            <FlexCol>
              <Text>{row?.productName}</Text>
              <Text level={6} color='Text3'>
                {row?.productCommonName}
              </Text>
              <Text level={6}>
                <StarFilled style={{ fontSize: 14, color: "#F4BF00" }} />
                &nbsp;{numberFormatter(row?.programPoint)}&nbsp;แต้ม&nbsp;/&nbsp;ตัน
              </Text>
            </FlexCol>
          </FlexRow>
        </>
      ),
    };
  };
  const expandColumns = [
    {
      title: "",
      dataIndex: "",
      key: "campaignProgramId",
      width: columnWidth[0] - 48,
      hideForSd: true,
      render: () => "",
    },
    {
      title: "",
      dataIndex: "productName",
      key: "productName",
      // width: columnWidth[0] + columnWidth[2],
      hideForDl: true,
      render: renderProduct,
    },
    {
      title: "",
      dataIndex: "productName",
      key: "productName",
      width: 380,
      hideForSd: true,
      render: renderProduct,
    },
    {
      title: "",
      dataIndex: "productCode",
      key: "productCode",
      width: columnWidth[3],
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <Text>{row?.saleUom}</Text>
              <br />
              <Text level={6} color='Text3'>
                {value}
              </Text>
            </>
          ),
        };
      },
    },
    {
      title: "ปริมาณที่สั่งซื้อ",
      dataIndex: "amount",
      key: "amount",
      width: columnWidth[4],
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <Text>{value}</Text>
              <br />
              <Text level={6} color='Text3'>
                {row?.unitName}
              </Text>
            </>
          ),
        };
      },
    },
    {
      title: "จำนวนตัน",
      dataIndex: "quantity",
      key: "quantity",
      width: columnWidth[5] + columnWidth[6],
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <Text>{value}</Text>
              <br />
              <Text level={6} color='Text3'>
                ตัน
              </Text>
            </>
          ),
        };
      },
    },
    {
      title: "แต้มที่ได้รับ",
      dataIndex: "point",
      key: "point",
      width: columnWidth[7],
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <Text>{value ? numberFormatter(value, 2) : "กำลังคำนวน"}</Text>
              <br />
              <Text level={6} color='Text3'>
                แต้ม
              </Text>
            </>
          ),
        };
      },
    },
    { title: "", key: "end", width: 48, hideForDl: true },
    { title: "", key: "end", width: 48 },
  ].filter(({ hideForDl, hideForSd }) => {
    if (activeProgram?.campaignProgramType === CampaignProgramType.ONLY_DEALER) {
      return !hideForDl;
    } else {
      return !hideForSd;
    }
  });

  const expandedRowRender = (expandDataSource) => (
    <GreyTable
      columns={expandColumns}
      dataSource={expandDataSource}
      pagination={false}
      showHeader={false}
    />
  );

  const handleTableChange = (pagination, filters, sorter) => {
    console.log(sorter);
    const { field, order } = sorter;
    setSortField(field);
    setSortOrder(mapSortingKey[order]);
  };

  const getParner = async (id: string, type: string) => {
    if (type === "DEALER") {
      await getPartnerDealer(id).then((res) => {
        setDataParner({ data: res, type: "DL" });
      });
    } else {
      await getPartnerSubdealer(id).then((res) => {
        setDataParner({ data: res, type: "SD" });
      });
    }
  };

  return (
    <>
      <CardContainer>
        <PageTitle />
        <br />
        <Container>
          <Row gutter={16}>
            {details.map((p, i) => (
              <Desc {...p} key={i} />
            ))}
          </Row>
        </Container>
        <Row gutter={16}>
          <Col span={8}>
            <TrackingItem
              title='รอรับแต้ม'
              number={campaignData?.sumPointWaitApprove}
              unit='แต้ม'
              icon={icon.crmWaitingPoint}
            />
          </Col>
          <Col span={8}>
            <TrackingItem
              title='ได้รับแต้ม'
              number={campaignData?.sumPointApprove}
              unit='แต้ม'
              icon={icon.crmPointIcon}
            />
          </Col>
          <Col span={8}>
            <TrackingItem
              title='ยกเลิกแต้ม'
              number={campaignData?.sumPointCancel}
              unit='แต้ม'
              icon={icon.crmCancelPoint}
            />
          </Col>
        </Row>
        <br />
        <FlexRow>
          {campaignData?.campaignProgram?.map((p) => {
            const { campaignProgramName, campaignProgramId } = p;
            const isActive = campaignProgramId === activeProgram?.campaignProgramId;
            return (
              <AntdButton
                key={campaignProgramId}
                size='large'
                style={{
                  marginRight: 8,
                  borderColor: color.secondary,
                  backgroundColor: isActive ? color.secondary : color.white,
                }}
                onClick={() => {
                  setActiveProgram(p);
                  resetPage();
                }}
                type={isActive ? "primary" : "default"}
              >
                {campaignProgramName}
              </AntdButton>
            );
          })}
        </FlexRow>
        <Container>
          <Row gutter={16}>
            {details2.map((p, i) => (
              <Desc {...p} key={i} />
            ))}
          </Row>
        </Container>
        <PrimaryTabsContainer>
          <Tabs
            data={tabsItems}
            onChange={(t) => setCurrentTab(t as HistoryCampaignType)}
            defaultTab={currentTab}
          />
        </PrimaryTabsContainer>
        <Row gutter={8} style={{ marginTop: 12 }}>
          <Col span={7}>
            <Input
              placeholder={
                activeProgram?.campaignProgramType === CampaignProgramType.ONLY_SUB_DEALER
                  ? "เลขที่ส่งเอการ / ชื่อร้านค้า"
                  : "เลขที่สั่งขาย / เลขที่ใบขน / ชื่อร้านค้า"
              }
              suffix={<SearchOutlined />}
              size='large'
              onPressEnter={(e) => {
                const value = (e.target as HTMLTextAreaElement).value;
                setSearch(value);
                resetPage();
              }}
            />
          </Col>
          <Col span={4}>
            <Select
              placeholder='เขตทั้งหมด'
              data={zoneData}
              value={zone}
              style={{ width: 150 }}
              onChange={(z) => {
                setZone(z);
                resetPage();
              }}
            />
          </Col>
          <Col span={9} />
          <Col span={4}>
            <FlexRow align='end' justify='end' style={{ width: "100%", height: "100%" }}>
              <Text>ทั้งหมด&nbsp;{programDataCount}&nbsp;รายการ</Text>
            </FlexRow>
          </Col>
        </Row>
        <br />
        {/* <TableContainer> */}
        <Table
          dataSource={programData?.map((p, key) => ({ ...p, key }))}
          loading={loadingProgram}
          columns={[...programColumn, Table.EXPAND_COLUMN]}
          pagination={{
            current: page,
            pageSize: 8,
            total: programDataCount,
            position: ["bottomCenter"],
            onChange: (p) => setPage(p),
          }}
          expandable={{
            fixed: "right",
            expandedRowRender: (r) => expandedRowRender(r?.campaignProduct),
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <UpOutlined onClick={(e) => onExpand(record, e)} />
              ) : (
                <DownOutlined onClick={(e) => onExpand(record, e)} />
              ),
          }}
          onChange={handleTableChange}
        />
        {/* </TableContainer> */}

        <Modal
          width={800}
          open={modalParner}
          closable={true}
          onCancel={() => setModalParner(!modalParner)}
          title={
            <>
              <Text fontWeight={600} fontSize={20}>
                คู่ค้ากับ {dataParner?.type === "DL" ? "Dealer" : "Sub-Dealer"}
              </Text>
              <br />
              <Text fontWeight={400} fontSize={16} color='Text3'>
                ทั้งหมด {dataParner?.data?.length} ร้านค้า
              </Text>
            </>
          }
          centered
          footer={false}
        >
          <Table
            pagination={false}
            dataSource={dataParner?.data?.map((p, key) => ({ ...p, key })) || []}
            columns={[
              {
                title: "#",
                dataIndex: "index",
                key: "index",
                render: (value: any, row: any, index: number) => {
                  return {
                    children: <Text>{index + 1}</Text>,
                  };
                },
              },
              {
                title: "รหัสร้านค้า",
                dataIndex: "customer_no",
                key: "customer_no",
                render: (value: any, row: any, index: number) => {
                  return {
                    children: <Text>{value || "-"}</Text>,
                  };
                },
              },
              {
                title: "ชื่อร้านค้า",
                dataIndex: "customer_name",
                key: "customer_name",
                render: (value: any, row: any, index: number) => {
                  return {
                    children: <Text>{value || "-"}</Text>,
                  };
                },
              },
              {
                title: "เขต",
                dataIndex: "zone",
                key: "zone",
                render: (value: any, row: any, index: number) => {
                  return {
                    children: <Text>{value || "-"}</Text>,
                  };
                },
              },
              {
                title: "จังหวัด",
                dataIndex: "province",
                key: "province",
                render: (value: any, row: any, index: number) => {
                  return {
                    children: <Text>{value || "-"}</Text>,
                  };
                },
              },
            ]}
          />
        </Modal>
      </CardContainer>
    </>
  );
};
