export interface CampaignStockEntities {
  campaignCode: string;
  campaignId: string;
  campaignName: string;
  countDealer: string;
  countDealerAll: string;
  endDate: string;
  isApproved: boolean;
  isDeleted: boolean;
  isEnabled: boolean;
  startDate: string;
  status: string;
  updateAt: string;
  updateBy: string;
}
export const CampaignStockEntities_INIT: CampaignStockEntities = {
  campaignCode: "",
  campaignId: "",
  campaignName: "",
  countDealer: "",
  countDealerAll: "",
  endDate: "",
  isApproved: false,
  isDeleted: false,
  isEnabled: false,
  startDate: "",
  status: "",
  updateAt: "",
  updateBy: "",
};
export interface AllCampaignStockEntities {
  count: number;
  data: CampaignStockEntities[];
  statusCount: number;
}
export const AllCampaignStockEntities_INIT: AllCampaignStockEntities = {
  count: 0,
  data: [CampaignStockEntities_INIT],
  statusCount: 0,
};
