import styled from "styled-components";
import color from "../../resource/color";
import { Table } from "antd";
import TableContainer from "./TableContainer";

export const GreyTableContainer = styled(TableContainer)`
  border: none !important;
  .ant-table-cell {
    background: #fff !important;
    border: none !important;
  }
  .ant-table-thead > tr > th {
    color: ${color.Text1} !important;
    font-family: "IBM Plex Sans Thai" !important;
    background: ${color.background1} !important;
    font-size: 16px;
    font-weight: 700;
    height: 54px;
  }
  .ant-table-thead > tr > th:not(:last-child)::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    width: 1px;
    height: 20px;
    background-color: #d7dcdf;
    z-index: 99;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #eff2f9 !important;
  }
  .ant-pagination-prev {
    width: 42px;
    height: 42px;
    border-radius: 6px 0 0 6px;
    padding: 6px;
    margin: 0;
    border: 1px solid ${color.Text3};
  }
  .ant-pagination-item {
    width: 42px;
    height: 42px;
    border-radius: 0;
    padding: 6px;
    margin: 0;
  }
  .ant-pagination-next {
    width: 42px;
    height: 42px;
    border-radius: 0 6px 6px 0;
    padding: 6px;
    border: 1px solid ${color.Text3};
  }
`;

const GreyTable = styled(Table)`
  .ant-table-cell {
    background: #fafafa !important;
  }
`;
export default GreyTable;
