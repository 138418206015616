import { checkCompany } from "./CompanyName";

const company = JSON.parse(localStorage.getItem("company")!);

export const websiteBackOffice = {
  orderManagement: [
    {
      label: "View Detail",
      value: "view",
    },

    {
      label: "Edit Status",
      value: "edit",
    },
  ],
  specialRequest: [
    {
      label: "View Detail",
      value: "view",
    },
    {
      label: "Approve Order",
      value: "edit",
    },
  ],
  promotionSetting: [
    {
      label: "View Detail",
      value: "view",
    },
    {
      label: "Create Promotion",
      value: "create",
    },
    {
      label: "Edit Promotion",
      value: "edit",
    },
    {
      label: "Delete Promotion",
      value: "delete",
    },
  ],
  detectOrder: [
    {
      label: "View Detail",
      value: "view",
    },
    {
      label: "Edit Promotion",
      value: "edit",
    },
  ],
  discountCo: [
    {
      label: "View Detail",
      value: "view",
    },
    {
      label: "Create Discount",
      value: "create",
    },
    {
      label: "Edit Discount",
      value: "edit",
    },
    {
      label: "Delete Discount",
      value: "delete",
    },
  ],
  priceListX10: [
    { label: "View Price List", value: "view" },
    {
      label: "Create Price List",
      value: "create",
    },
    {
      label: "Edit Price List",
      value: "edit",
    },
    {
      label: "Delete Price List",
      value: "delete",
    },
  ],
  saleManagement: [
    {
      label: "View Detail",
      value: "view",
    },
    {
      label: "Create User",
      value: "create",
    },
    {
      label: "Edit User",
      value: "edit",
    },
    {
      label: "Delete User",
      value: "delete",
    },
  ],
  roleManagement: [
    {
      label: "View Role",
      value: "view",
    },
    {
      label: "Create Role",
      value: "create",
    },
    {
      label: "Edit Role",
      value: "edit",
    },
    {
      label: "Delete Role",
      value: "delete",
    },
  ],
  productManagement: [
    {
      label: "View Product",
      value: "view",
    },
    {
      label: "Edit Product",
      value: "edit",
    },
  ],
  oneFinity: [
    {
      label: "View Setting",
      value: "view",
    },
    {
      label: "Edit Setting",
      value: "edit",
    },
  ],
  productBrandSetting: [
    {
      label: "Create Setting",
      value: "create",
    },
    {
      label: "Edit Setting",
      value: "edit",
    },
  ],
};

interface NestedList {
  label: string;
  value: string;
}

interface Group {
  groupNameNested?: string;
  listNested: NestedList[];
}

interface MainList {
  label?: string;
  value?: string;
  groupNameNested?: string;
  listNested?: NestedList[];
}

interface RoleGroup {
  isNested?: boolean;
  groupKey: string;
  groupName: string;
  list: (MainList | Group)[];
}

interface StaticRoles {
  [key: string]: RoleGroup;
}

export const staticRolesObject: StaticRoles = {
  report: {
    isNested: true,
    groupName: "รายงาน",
    groupKey: "REPORT",
    list: [
      {
        label: "โปรโมชัน",
        groupNameNested: "promotionReport",
        listNested: [
          {
            label: "ดูรายงานโปรโมชัน",
            value: "view",
          },
        ],
      },
      {
        label: "แคมเปญพอยน์",
        groupNameNested: "reportCampaignPoint",
        listNested: [
          {
            label: "ดูรายงานแคมเปญพอยน์",
            value: "view",
          },
        ],
      },
    ],
  },
  manageOrder: {
    groupName: "จัดการคำสั่งซื้อ",
    groupKey: "ORDER",
    list: [
      {
        label: "ดูคำสั่งซื้อ",
        value: "view",
      },
      {
        label: "แก้ไขสถานะคำสั่งซื้อ",
        value: "edit",
      },
      {
        label: "แสดงราคา",
        value: "showPrice",
      },
    ],
  },
  specialRequest: {
    groupName: "อนุมัติคำสั่งซื้อ (Special Request)",
    groupKey: "SPREQ",
    list: [
      {
        label: "ดูคำสั่งซื้อ",
        value: "view",
      },
      {
        label: "อนุมัติ/ ยกเลิก คำสั่งซื้อ",
        value: "approve",
      },
    ],
  },
  receiveGoods: {
    groupName: "รายการรับสินค้า",
    groupKey: "RECEIVE",
    list: [
      {
        label: "ดูรายการรับสินค้า",
        value: "view",
      },
      {
        label: "ยกเลิกการรับสินค้า",
        value: "cancel",
      },
    ],
  },
  crm: {
    isNested: true,
    groupName: "crm",
    groupKey: "crm",
    list: [
      {
        label: "แคมเปญพอยท์",
        groupNameNested: "campaignPoint",
        listNested: [
          {
            label: "ดูรายการแคมเปญ",
            value: "view",
          },
          {
            label: "สร้างแคมเปญ",
            value: "create",
          },
          {
            label: "ดูสถิติแคมเปญ",
            value: "report",
          },
          {
            label: "แก้ไขแคมเปญ",
            value: "edit",
          },
          {
            label: "ลบแคมเปญ",
            value: "delete",
          },
          {
            label: "อนุมัติแคมเปญ",
            value: "approve",
          },
        ],
      },
      {
        label: "รายงานรายร้าน",
        groupNameNested: "customerPointReport",
        listNested: [
          {
            label: "ดูรายงาน",
            value: "view",
          },
        ],
      },
      {
        label: "รายการอนุมัติแต้ม",
        groupNameNested: "approvePointList",
        listNested: [
          {
            label: "ดูรายงาน",
            value: "view",
          },
        ],
      },
    ],
  },
  productList: {
    isNested: true,
    groupName: "จัดการสินค้า",
    groupKey: "PROD",
    list: [
      {
        label: "รายการสินค้า",
        groupNameNested: "productList",
        listNested: [
          {
            label: "ดูสินค้า",
            value: "view",
          },
          {
            label: "แก้ไขสินค้า",
            value: "edit",
          },
          {
            label: "เชื่อมต่อ Navision",
            value: "sync",
          },
        ],
      },
      {
        label: "ราคาสินค้าเฉพาะร้าน",
        groupNameNested: "priceSpecialExclusive",
        listNested: [
          {
            label: "ดูราคาสินค้า",
            value: "view",
          },
          {
            label: "สร้างสินค้า",
            value: "create",
          },
          {
            label: "แก้ไขสินค้า",
            value: "edit",
          },
        ],
      },
      {
        label: "สินค้าขายเฉพาะร้าน",
        groupNameNested: "productShop",
        listNested: [
          {
            label: "ดูสินค้า",
            value: "view",
          },
          {
            label: "แก้ไขสินค้า",
            value: "edit",
          },
        ],
      },
    ],
  },
  promotionSetting: {
    isNested: true,
    groupName: "โปรโมชั่น",
    groupKey: "PROMOTION",
    list: [
      {
        label: "ตั้งค่าโปรโมชั่น",
        groupNameNested: "promotion",
        listNested: [
          {
            label: "ดูโปรโมชั่น",
            value: "view",
          },
          {
            label: "สร้างโปรโมชั่น",
            value: "create",
          },
          {
            label: "แก้ไขโปรโมชั่น",
            value: "edit",
          },
          {
            label: "ลบโปรโมชั่น",
            value: "delete",
          },
          {
            label: "อนุมัติโปรโมชั่น",
            value: "approve",
          },
          // {
          //   label: "จัดการเอกสารโปรโมชั่น",
          //   value: "document",
          // },
        ],
      },
      {
        label: "แจ้งเตือนโปรโมชั่น",
        groupNameNested: "promotionNoti",
        listNested: [
          {
            label: "ดูการแจ้งเตือน",
            value: "view",
          },
          {
            label: "สร้างการแจ้งเตือน",
            value: "create",
          },
          {
            label: "แก้ไขการแจ้งเตือน",
            value: "edit",
          },
          {
            label: "ลบการแจ้งเตือน",
            value: "delete",
          },
        ],
      },
    ],
  },
  detectOrder: {
    isNested: true,
    groupName: "ตรวจจับยอดสั่งซื้อ",
    groupKey: "DETECTION",
    list: [
      {
        label: "ตั้งค่าสต๊อคร้านค้า",
        groupNameNested: "stockSetting",
        listNested: [
          {
            label: "ดูตั้งค่าสต๊อคร้านค้า",
            value: "view",
          },
          {
            label: "แก้ไขตั้งค่าสต๊อคร้านค้า",
            value: "edit",
          },
        ],
      },
      {
        label: "ตั้งค่าเกณฑ์ตรวจจับ",
        groupNameNested: "stockSetting",
        listNested: [
          {
            label: "ดูเกณฑ์ตรวจจับ",
            value: "view",
          },
          {
            label: "แก้ไขเกณฑ์ตรวจจับ",
            value: "edit",
          },
        ],
      },
    ],
  },
  freebieList: {
    groupName: "รายการของแถม",
    groupKey: "FREEB",
    list: [
      {
        label: "ดูรายการของแถม",
        value: "view",
      },
      {
        label: "แก้ไขของแถม",
        value: "edit",
      },
      {
        label: "เชื่อมต่อ Navision",
        value: "sync",
      },
    ],
  },
  DOReport: {
    groupName: "รายการเตรียมจัดส่ง",
    groupKey: "DOREPORT",
    list: [
      {
        label: "ดูรายการเตรียมจัดส่ง",
        value: "view",
      },
    ],
  },
  discountCo: {
    isNested: true,
    groupName: "ส่วนลดดูแลราคา",
    groupKey: "DISCO",
    list: [
      {
        label: "รายการส่วนลด",
        groupNameNested: "discountList",
        listNested: [
          {
            label: "ดูรายการส่วนลด",
            value: "view",
          },
          {
            label: "สร้างรายการส่วนลด",
            value: "create",
          },
          {
            label: "แก้ไขรายการส่วนลด",
            value: "edit",
          },
          {
            label: "ลบรายการส่วนลด",
            value: "delete",
          },
          {
            label: "อนุมัติรายการส่วนลด",
            value: "approve",
          },
        ],
      },
      {
        label: "ส่วนลดดูแลราคารายร้าน",
        groupNameNested: "manageConditionStore",

        listNested: [
          {
            label: "ดูส่วนลดดูแลราคารายร้าน",
            value: "view",
          },
          {
            label: "สร้างส่วนลดดูแลราคารายร้าน",
            value: "create",
          },
          {
            label: "แก้ไขส่วนลดดูแลราคารายร้าน",
            value: "edit",
          },
        ],
      },
      {
        groupNameNested: "manageCondition",
        label: "จัดการเงื่อนไขดูแลราคา",
        listNested: [
          {
            label: "ดูจัดการเงื่อนไขดูแลราคา",
            value: "view",
          },
          {
            label: "สร้างจัดการเงื่อนไขดูแลราคา",
            value: "create",
          },
          {
            label: "แก้ไขจัดการเงื่อนไขดูแลราคา",
            value: "edit",
          },
          {
            label: "ลบจัดการเงื่อนไขดูแลราคา",
            value: "delete",
          },
          {
            label: "อนุมัติจัดการเงื่อนไขดูแลราคา",
            value: "approve",
          },
        ],
      },
    ],
  },
  news: {
    isNested: true,
    groupName: "ข่าวสาร",
    groupKey: "NEWS",
    list: [
      {
        label: "รายการข่าวสาร",
        groupNameNested: "newsList",
        listNested: [
          {
            label: "สร้างรายการข่าวสาร",
            value: "create",
          },
          {
            label: "แก้ไขรายการข่าวสาร",
            value: "edit",
          },
          {
            label: "ลบรายการข่าวสาร",
            value: "delete",
          },
        ],
      },
      {
        label: "ปักหมุดข่าวสาร",
        groupNameNested: "pinedNews",
        listNested: [
          {
            label: "แก้ไขปักหมุดข่าวสาร",
            value: "edit",
          },
        ],
      },
      {
        groupNameNested: "highlightNews",
        label: "ข่าวสารไฮไลท์",
        listNested: [
          {
            label: "สร้างข่าวสารไฮไลท์",
            value: "create",
          },
          {
            label: "แก้ไขข่าวสารไฮไลท์",
            value: "edit",
          },
          {
            label: "ลบข่าวสารไฮไลท์",
            value: "delete",
          },
        ],
      },
    ],
  },
};
export const staticManageRoles: StaticRoles = {
  // Manage
  manageUser: {
    isNested: true,
    groupName: "จัดการผู้ใช้งาน",
    groupKey: "USER",
    list: [
      {
        label: "รายชื่อผู้ใช้งาน",
        groupNameNested: "userList",
        listNested: [
          {
            label: "ดูรายชื่อผู้ใช้งาน",
            value: "view",
          },
          {
            label: "เพิ่มผู้ใช้งาน",
            value: "create",
          },
          {
            label: "แก้ไขผู้ใช้งาน",
            value: "edit",
          },
          {
            label: "ลบผู้ใช้งาน",
            value: "delete",
          },
        ],
      },
      {
        label: "จัดการสิทธิ์",
        groupNameNested: "manageRoles",
        listNested: [
          {
            label: "ดูรายการบทบาทผู้ใช้งาน",
            value: "view",
          },
          {
            label: "เพิ่มบทบาทผู้ใช้งาน",
            value: "create",
          },
          {
            label: "แก้ไขบทบาทผู้ใช้งาน",
            value: "edit",
          },
          {
            label: "ลบบทบาทผู้ใช้งาน",
            value: "delete",
          },
        ],
      },
    ],
  },
  manageStore: {
    isNested: true,
    groupName: "จัดการร้านค้า",
    groupKey: "STORE",
    list: [
      // {
      //   label: "รายชื่อร้านค้า",
      //   groupNameNested: "storeList",
      //   listNested: [
      //     {
      //       label: "ดูรายชื่อร้านค้า",
      //       value: "view",
      //     },
      //     {
      //       label: "สร้างร้านค้า",
      //       value: "create",
      //     },
      //     {
      //       label: "แก้ไขร้านค้า",
      //       value: "edit",
      //     },
      //     {
      //       label: "เชื่อมต่อ Navision",
      //       value: "sync",
      //     },
      //   ],
      // }, รายชื่อร้านค้า ver. เก่า
      {
        label: "รายชื่อร้านค้า",
        groupNameNested: "corporateShop",
        listNested: [
          {
            label: "ดูรายชื่อร้านค้า",
            value: "view",
          },
          {
            label: "แก้ไขร้านค้า",
            value: "edit",
          },
          {
            label: "จัดการผู้ใช้งานลูกค้า",
            value: "manageUser",
          },
          {
            label: "อัปเดทร้านค้าใหม่",
            value: "sync",
          },
          {
            label: "เพิ่ม Sub-Dealer",
            value: "subdealer",
          },
        ],
      },
      {
        label: "จัดกลุ่มร้านค้า",
        groupNameNested: "shopGroup",
        listNested: [
          {
            label: "ดูกลุ่มร้านค้า",
            value: "view",
          },
          {
            label: "สร้างกลุ่มร้านค้า",
            value: "create",
          },
          {
            label: "แก้ไขกลุ่มร้านค้า",
            value: "edit",
          },
        ],
      },
      {
        label: "อนุมัติเบอร์โทรศัพท์",
        groupNameNested: "approvePhone",
        listNested: [
          {
            label: "ดูรายการอนุมัติ",
            value: "view",
          },
          {
            label: "อนุมัติการเปลี่ยนเบอร์มือถือร้านค้า",
            value: "approve",
          },
        ],
      },
    ],
  },
  generalSettings: {
    isNested: true,
    groupName: "ตั้งค่า",
    groupKey: "GENERALSETTING", //generalSettings
    list: checkCompany(company?.companyCode)
      ? [
          {
            label: "แบรนด์สินค้า",
            groupNameNested: "productBrandSetting",
            listNested: [
              {
                label: "สร้างแบรนด์สินค้า",
                value: "create",
              },
              {
                label: "แก้ไขแบรนด์สินค้า",
                value: "edit",
              },
            ],
          },
          {
            label: "กลุ่มสินค้า",
            groupNameNested: "productGroup",
            listNested: [
              {
                label: "สร้างกลุ่มสินค้า",
                value: "create",
              },
              {
                label: "แก้ไขกลุ่มสินค้า",
                value: "edit",
              },
            ],
          },
          {
            label: "หมวดสินค้า",
            groupNameNested: "prodCategory",
            listNested: [
              {
                label: "สร้างหมวดสินค้า",
                value: "create",
              },
              {
                label: "แก้ไขหมวดสินค้า",
                value: "edit",
              },
            ],
          },
          {
            label: "เขต",
            groupNameNested: "zoneSetting",
            listNested: [
              {
                label: "สร้างเขต",
                value: "create",
              },
              {
                label: "แก้ไขเขต",
                value: "edit",
              },
            ],
          },
        ]
      : [
          {
            label: "แบรนด์สินค้า",
            groupNameNested: "productBrandSetting",
            listNested: [
              {
                label: "สร้างแบรนด์สินค้า",
                value: "create",
              },
              {
                label: "แก้ไขแบรนด์สินค้า",
                value: "edit",
              },
            ],
          },
          {
            label: "กลุ่มสินค้า",
            groupNameNested: "productGroup",
            listNested: [
              {
                label: "สร้างกลุ่มสินค้า",
                value: "create",
              },
              {
                label: "แก้ไขกลุ่มสินค้า",
                value: "edit",
              },
            ],
          },
          {
            label: "หมวดสินค้า",
            groupNameNested: "prodCategory",
            listNested: [
              {
                label: "สร้างหมวดสินค้า",
                value: "create",
              },
              {
                label: "แก้ไขหมวดสินค้า",
                value: "edit",
              },
            ],
          },
          {
            label: "เขต",
            groupNameNested: "zoneSetting",
            listNested: [
              {
                label: "สร้างเขต",
                value: "create",
              },
              {
                label: "แก้ไขเขต",
                value: "edit",
              },
            ],
          },
          {
            label: "โรงงาน",
            groupNameNested: "factorySetting",
            listNested: [
              {
                label: "สร้างโรงงาน",
                value: "create",
              },
              {
                label: "แก้ไขโรงงาน",
                value: "edit",
              },
            ],
          },
          {
            label: "หน่วยสินค้า",
            groupNameNested: "unitSetting",
            listNested: [
              {
                label: "สร้างหน่วยสินค้า",
                value: "create",
              },
              {
                label: "แก้ไขหน่วยสินค้า",
                value: "edit",
              },
            ],
          },
        ],
  },
};

export const mockRoles = [
  {
    menuName: "report",
    menu: {
      promotionReport: ["view"],
      reportCampaignPoint: ["view"],
    },
  },
  {
    menuName: "manageOrder",
    menu: ["view", "edit", "showPrice"],
  },
  {
    menuName: "specialRequest",
    menu: ["view", "approve"],
  },
  {
    menuName: "receiveGoods",
    menu: ["view", "cancel"],
  },
  {
    menuName: "productList",
    menu: {
      productList: ["view", "edit", "sync"],
      priceSpecialExclusive: ["view", "create", "edit", "delete"],
      productShop: ["view", "edit"],
    },
  },
  {
    menuName: "promotionSetting",
    menu: {
      promotion: ["create", "view", "edit", "delete", "approve"],
      promotionNoti: ["create", "view", "edit", "delete"],
    },
  },
  {
    menuName: "detectOrder",
    menu: {
      stockSetting: ["view", "edit"],
      thresholdSetting: ["create", "view", "edit", "delete", "approve"],
    },
  },
  {
    menuName: "freebieList",
    menu: ["view", "edit", "sync"],
  },
  {
    menuName: "discountCo",
    menu: {
      discountList: ["create", "view", "edit", "delete", "approve"],
      manageConditionStore: ["create", "view", "edit", "delete", "approve"],
      manageCondition: ["create", "view", "edit"],
    },
  },
  {
    menuName: "news",
    menu: {
      newsList: ["create", "edit", "delete"],
      pinedNews: ["edit"],
      highlightNews: ["create", "edit", "delete"],
    },
  },
  {
    menuName: "manageUser",
    menu: {
      userList: ["view", "create", "edit", "delete"],
      manageRoles: ["view", "create", "edit", "delete"],
    },
  },
  {
    menuName: "manageStore",
    menu: {
      storeList: ["view", "create", "edit", "sync"],
      approvePhone: ["view", "approve"],
      shopGroup: ["view", "create", "edit"],
      corporateShop: ["view", "edit", "manageUser", "sync", "subdealer"],
    },
  },
  {
    menuName: "generalSettings",
    menu: {
      productBrandSetting: ["create", "edit"],
      productGroup: ["create", "edit"],
      prodCategory: ["create", "edit"],
      zoneSetting: ["create", "edit"],
      factorySetting: ["create", "edit"],
      unitSetting: ["create", "edit"],
    },
  },
  {
    menuName: "crm",
    menu: {
      campaignPoint: ["view", "create", "edit", "approve", "report", "delete"],
      customerPointReport: ["view"],
      approvePointList: ["view"],
    },
  },
  {
    menuName: "DOReport",
    menu: ["view"],
  },
];
