import { AxiosResponse } from "axios";
import { BASE_URL, httpClient } from "../config/develop-config";

const getCampaignStock = async (
  company: string,
  status: string,
  page: number,
  take: number,
  search?: string,
  startDate?: string,
  endDate?: string,
) => {
  const params = {
    company: company,
    status: status,
    search: search,
    startDate: startDate,
    endDate: endDate,
    page: page,
    take: take,
  };
  return await httpClient
    .get(`${BASE_URL}/crm/stock/campaign-stock`, { params })
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};

export { getCampaignStock };
