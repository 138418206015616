import React, { useEffect, useState } from "react";
import { Table, Row, Col, Image, Tag, Modal, message, Divider, Tabs } from "antd";
import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  SearchOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { numberFormatter } from "../../../utility/Formatter";
import { FlexCol, FlexRow } from "../../../components/Container/Container";
import Text from "../../../components/Text/Text";
import { STATUS_COLOR_MAPPING } from "../../../definitions/product";
import color from "../../../resource/color";
import image from "../../../resource/image";
import { CardContainer, OuterCardContainer } from "../../../components/Card/CardContainer";
import { checkPermission } from "../../../components/Permission/Permission";
import { useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import { roleAtom } from "../../../store/RoleAtom";
import Input from "../../../components/Input/Input";
import Button from "../../../components/Button/Button";
import icon from "../../../resource/icon";
import TableContainer from "../../../components/Table/TableContainer";
import { GreyTableContainer } from "../../../components/Table/GreyTable";
import dayjs from "dayjs";
import { ActionBtn } from "../../../components/Button/TableActionButton";
import TablePagination from "../../../components/Table/TablePagination";
import { useEffectOnce } from "react-use";
import PageTitleNested from "../../../components/PageTitle/PageTitleNested";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import CreateEdit from "./SharedComponent/CreateEdit";
import { PrimaryTabsContainer } from "../../../components/AntdTabs/AntdTabs";

const EditThreshold: React.FC = () => {
  const pageSize = 8;
  const userProfile = JSON.parse(localStorage.getItem("profile")!);
  const { firstname, lastname } = userProfile;
  const company = JSON.parse(localStorage.getItem("company")!);

  const { pathname } = window.location;
  const pathSplit = pathname.split("/") as Array<string>;
  const id = pathSplit[4];
  const isEditing = !!id;

  const navigate = useNavigate();

  const pageName = isEditing ? "แก้ไขกลุ่มตรวจจับร้านค้า" : "เพิ่มกลุ่มตรวจจับร้านค้า";

  const [keyword, setKeyword] = useState<string>();
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState(false);
  const [dataState, setDataState] = useState<any>();

  const tabs = {
    main: "main",
    history: "history",
  };
  const [activeTab, setActiveTab] = useState(tabs.main);

  useEffectOnce(() => {
    if (isEditing) fetchData();
  });

  const fetchData = async () => {
    setLoading(true);
    try {
      setDataState({
        test: true,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const HistoryMenu = () => {
    const columns = [
      {
        title: "วันเวลาอัปเดท",
        dataIndex: "customerNo",
        key: "customerNo",
        render: (value: any, row: any, index: number) => {
          return {
            children: <Text>{value}</Text>,
          };
        },
      },
      {
        title: "กิจกรรม",
        dataIndex: "customerName",
        key: "customerName",
        render: (value: any, row: any, index: number) => {
          return {
            children: (
              <>
                <Text>{value}</Text>
                <br />
                <Text level={6} color='Text3'>
                  {row?.customer?.taxNo}
                </Text>
              </>
            ),
          };
        },
      },
      {
        title: "ผู้ใช้งาน",
        dataIndex: "partner",
        key: "partner",
        render: (value: any, row: any, index: number) => {
          return {
            children: <Text level={6}>{value ? "Dealer" : "Subdealer"}</Text>,
          };
        },
      },
    ];
    return (
      <>
        <TableContainer>
          <Table
            columns={columns}
            // dataSource={filteredProd?.map((p) => ({ ...p, key: p.productId }))}
          />
        </TableContainer>
      </>
    );
  };

  const tabsItems = [
    {
      key: "0",
      label: "",
      disabled: true,
    },
    {
      key: tabs.main,
      label: "รายละเอียด",
    },
    {
      key: tabs.history,
      label: "ประวัติทำรายการ",
    },
  ];

  return (
    <>
      <OuterCardContainer>
        <CardContainer cutBottom>
          <PageTitleNested
            title={pageName}
            customBreadCrumb={
              <BreadCrumb
                data={[
                  { text: "เกณฑ์ตรวจจับ", path: "/DetectOrderPage/threshold" },
                  {
                    text: pageName,
                    path: window.location.pathname,
                  },
                ]}
              />
            }
          />
        </CardContainer>
        <PrimaryTabsContainer>
          <Tabs items={tabsItems} activeKey={activeTab} onChange={(t) => setActiveTab(t)} />
        </PrimaryTabsContainer>
        <CardContainer cutTop>
          {activeTab === tabs.main ? <CreateEdit /> : <HistoryMenu />}
        </CardContainer>
      </OuterCardContainer>
    </>
  );
};

export default EditThreshold;
