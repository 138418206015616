import { color } from "../resource";

export const MAP_STATUS_STOCK = [
  { key: "ALL", value: "ALL", label: "ทั้งหมด" },
  { key: "READY", value: "READY", label: "รอเริ่ม" },
  { key: "WAIT", value: "WAIT", label: "รออนุมัติ" },
  { key: "RUNNING", value: "RUNNING", label: "เปิดใช้งาน" },
  { key: "CLOSED", value: "CLOSED", label: "ปิดใช้งาน" },
];
export const STOCK_STATUS_COLOR: any = {
  READY: color["secondary2"],
  WAIT: color["warning"],
  RUNNING: color["success"],
  CLOSED: color["placeholder"],
};
export const STOCK_STATUS_WORD: any = {
  READY: "รอเริ่ม",
  WAIT: "รออนุมัติ",
  RUNNING: "เปิดใช้งาน",
  CLOSED: "ปิดใช้งาน",
};
