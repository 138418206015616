import React, { useEffect, useMemo, useState } from "react";
import Text from "../../components/Text/Text";
import { Col, Row, Spin } from "antd";
import CardPointCampaign from "../../components/Card/CardPointCampaign";
import { formatDateRange, numberFormatter } from "../../utility/Formatter";
import FilterCampaign from "../../components/Filter/FilterCampaign";
import color from "../../resource/color";
import CampaignStatist from "./CampaignStatist";
import CampaignList from "./TableList/CampaignList";
import ProductList from "./TableList/ProductList";
import RewardList from "./TableList/RewardList";
import ZoneList from "./TableList/ZoneList";
import { reportCrmPoint } from "../../datasource/ReportCRMPointDatasource";
import { ReportCRMEntities, ReportCRMEntities_INIT } from "../../entities/ReportCRMEntity";
import { getDateRange } from "../../utility/dateUtils";

function ReportCampaignPoint() {
  const userProfile = JSON.parse(localStorage.getItem("profile")!);
  const [searchText, setSearchText] = useState<any>();
  const [type, setType] = useState<string>("");
  const [filterTarget, setFilterTarget] = useState(["REBATE"]);
  const [filterPoint, setFilterPoint] = useState<any[]>(
    userProfile?.company === "ICPL" ? ["ONLY_DEALER"] : ["ONLY_SUB_DEALER"],
  );
  const [loading, setLoading] = useState(false);
  const [statistics, setStatistics] = useState<ReportCRMEntities>(ReportCRMEntities_INIT);
  const [filterCondition, setFilterCondition] = useState<any[]>(
    userProfile?.company === "ICPL" ? ["OPEN_ORDER"] : ["SUBMIT_DELIVERY_DOC"],
  );
  const [exporting, setExporting] = useState<boolean>(false);
  const currentDate = new Date();
  const defaultStartDate = new Date(currentDate);
  defaultStartDate.setMonth(currentDate.getMonth() - 3);

  const [filterDay, setFilterDay] = useState<{ startDate: Date; endDate: Date }>({
    startDate: currentDate,
    endDate: defaultStartDate,
  });
  const checkTarget = filterTarget.includes("Redeem");

  const handleFilterTarget = (target: any[]) => {
    setFilterTarget(target);
  };

  const handleFilterPoint = (point: any[]) => {
    setFilterPoint(point);
  };

  const handleFilterCondition = (condition: any[]) => {
    setFilterCondition(condition);
  };

  const filterDate = (range: { startDate: Date; endDate: Date; key: string }) => {
    setFilterDay({ startDate: range.startDate, endDate: range.endDate });
  };

  const convertDate = useMemo(
    () => ({
      startDate: filterDay ? new Date(filterDay.startDate) : new Date(),
      endDate: filterDay ? new Date(filterDay.endDate) : new Date(),
    }),
    [filterDay],
  );
  const dateRange = formatDateRange(convertDate.startDate, convertDate.endDate);

  useEffect(() => {
    if (searchText?.data) {
      const groupedCampaigns = searchText.data.campaignPrograms.reduce((acc, curr) => {
        const key = `${curr.campaignProgramType}-${curr.campaignProgramCondition}`;
        if (!acc[key]) {
          acc[key] = {
            campaignProgramType: curr.campaignProgramType,
            campaignProgramCondition: curr.campaignProgramCondition,
            campaignPrograms: [],
          };
        }
        acc[key].campaignPrograms.push(curr);
        return acc;
      }, {});

      const groupedArray = Object.values(groupedCampaigns);
      setFilterTarget(searchText.data.objective);
      const filterPoints = groupedArray.map((group: any) => group.campaignProgramType);
      const filterConditions = groupedArray.map((group: any) => group.campaignProgramCondition);
      const uniqueFilterPoints = [...new Set(filterPoints)];
      const uniqueFilterConditions = [...new Set(filterConditions)];
      setFilterPoint(uniqueFilterPoints);
      setFilterCondition(uniqueFilterConditions);
    }
  }, [searchText?.data]);
  useEffect(() => {
    reportCRM();
  }, [filterPoint, filterCondition, filterDay, searchText?.campaignId, type]);

  const reportCRM = async () => {
    setLoading(true);
    try {
      const { startDate, endDate } = getDateRange(type, filterDay);
      const object = {
        company: userProfile?.company,
        startDate: type === 'Today' ? startDate : `${startDate} 00:00:00`,
        endDate: type === 'Today' ? endDate : `${endDate} 23:59`,
        campaignId: searchText?.data?.campaignId || undefined,
        objective: filterTarget,
        campaignProgramType: filterPoint,
        campaignProgramCondition: filterCondition,
        statusHour: type === "Today" ? true : false,
      };
      await new Promise((resolve) => setTimeout(resolve, 300));
      const res = await reportCrmPoint(object);
      setStatistics(res);
    } catch (error) {
      console.log("err report-crm-point", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin size='large' spinning={exporting}>
      <div
        style={{
          padding: "28px",
          backgroundColor: "white",
          borderTopRightRadius: "12px",
          borderTopLeftRadius: "12px",
          borderBottom: `1px solid ${color.background2}`,
        }}
      >
        <Text fontSize={24} fontWeight={700} color='Text2'>
          รายงานแคมเปญพอยท์
        </Text>
      </div>
      <div
        style={{
          padding: "28px",
          backgroundColor: "white",
        }}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className='gutter-row' span={12}>
            <CardPointCampaign
              title={"แคมเปญทั้งหมด"}
              amount={numberFormatter(statistics.summaryCampaign.countCampaignAll, 0)}
              pointProgram={numberFormatter(statistics.summaryCampaign.countProgramAll, 0)}
            />
          </Col>
          <Col className='gutter-row' span={12}>
            <CardPointCampaign
              title={"แคมเปญปัจจุบัน "}
              label='(เปิดใช้งาน)'
              amount={numberFormatter(statistics.summaryCampaign.countCampaignCurrent, 0)}
              pointProgram={numberFormatter(statistics.summaryCampaign.countProgramCurrent, 0)}
            />
          </Col>
        </Row>
      </div>
      <div style={{ marginBottom: 18 }}>
        <FilterCampaign
          searchText={searchText}
          setSearchText={setSearchText}
          filterDate={filterDate}
          filterTarget={filterTarget}
          setFilterTarget={handleFilterTarget}
          filterPoint={filterPoint}
          setFilterPoint={handleFilterPoint}
          filterCondition={filterCondition}
          setFilterCondition={handleFilterCondition}
          typeDate={(type: string) => setType(type)}
        />
      </div>
      <div style={{ marginBottom: 18 }}>
        <CampaignStatist
          loadingExport={(e) => setExporting(e)}
          loading={loading}
          filterTarget={filterTarget}
          filterDay={filterDay}
          typeDate={type}
          summaryStatistics={statistics.summaryStatistics}
          statisticsGraph={statistics.statisticsGraph}
          campaignProgramType={filterPoint}
          campaignProgramCondition={filterCondition}
          campaignId={searchText?.data?.campaignId}
        />
      </div>
      <div style={{ marginBottom: 18 }}>
        <CampaignList
          dateRange={dateRange}
          typeDate={type}
          campaignProgramType={filterPoint}
          filterDay={filterDay}
          campaignProgramCondition={filterCondition}
          objective={filterTarget}
          campaignId={searchText?.data?.campaignId}
        />
      </div>
      <div style={{ marginBottom: 18 }}>
        <ZoneList
          dateRange={dateRange}
          typeDate={type}
          campaignProgramType={filterPoint}
          filterDay={filterDay}
          campaignProgramCondition={filterCondition}
          objective={filterTarget}
          campaignId={searchText?.data?.campaignId}
        />
      </div>
      <div style={{ marginBottom: 18 }}>
        <Row gutter={{ xs: 8, sm: 16 }}>
          <Col className='gutter-row' span={!checkTarget ? 24 : 12}>
            <ProductList
              dateRange={dateRange}
              typeDate={type}
              campaignProgramType={filterPoint}
              filterDay={filterDay}
              campaignProgramCondition={filterCondition}
              objective={filterTarget}
              campaignId={searchText?.data?.campaignId}
            />
          </Col>
          {checkTarget && (
            <Col className='gutter-row' span={12}>
              <RewardList dateRange={dateRange} data={[]} typeDate={type} />
            </Col>
          )}
        </Row>
      </div>
    </Spin>
  );
}

export default ReportCampaignPoint;
