import {
  Col,
  Divider,
  Form,
  Radio,
  Row,
  Space,
  Upload,
  Select,
  Image,
  Checkbox,
  Table,
  Modal,
} from "antd";
import { useForm, useWatch } from "antd/lib/form/Form";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import { CardContainer } from "../../../components/Card/CardContainer";
import PageTitleNested from "../../../components/PageTitle/PageTitleNested";
import StepAntd from "../../../components/StepAntd/StepAntd";
import color from "../../../resource/color";
import Text from "../../../components/Text/Text";
import Input from "../../../components/Input/Input";
import Selects from "../../../components/Select/Select";
import Button from "../../../components/Button/Button";
import { getMasterAddress, getProvince } from "../../../datasource/AddressDatasource";
import _ from "lodash";
import {
  createCustomerEx,
  deleteFile,
  getCustomersById,
  updateCustomerEx,
  uploadFile,
} from "../../../datasource/CustomerDatasource";
import { getProductBrand } from "../../../datasource/ProductDatasource";
import { zoneDatasource } from "../../../datasource/ZoneDatasource";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import image from "../../../resource/image";
import { CheckCircleTwoTone, DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import { StoreEntity, ZoneEntity } from "../../../entities/StoreEntity";
import { FlexCol, FlexRow } from "../../../components/Container/Container";
import { ModalSelectStore } from "../../Shared/ModalSelectStore";
import TableContainer from "../../../components/Table/TableContainer";
import GoogleMaps from "../../../components/Map/GoogleMaps";

export const AntSelectCustom = styled(Select)`
  .ant-select-selector {
    height: 40px !important;
    display: flex;
    align-items: center;
    font-family: Sarabun !important;
  }
  .ant-select-selection-placeholder {
    font-family: Sarabun !important;
  }

  .ant-select-selection-item {
    border-radius: 4px;
    font-family: Sarabun !important;
    font-weight: 400 !important;
    display: flex;
    align-items: center;
    color: #000000;
  }
`;

export const CreateCorporateShop: React.FC = () => {
  const navigate = useNavigate();
  const taxNo = localStorage.getItem("taxNo")!;

  const userProfile = JSON.parse(localStorage.getItem("profile")!);
  const company = JSON.parse(localStorage.getItem("company")!);

  const [form1] = useForm();
  const [form2] = useForm();

  const { pathname } = window.location;
  const pathSplit = pathname.split("/") as Array<string>;
  const isEditing = pathSplit[3] !== "create";
  const id = isEditing ? pathSplit[3] : pathSplit[4];

  const [current, setCurrent] = useState(0);
  const [imageUrl, setImageUrl] = useState<string>();
  const [center, setCenter] = useState<any>({ lat: 13.736717, lng: 100.523186 });
  const [isDL, setIsDL] = useState<boolean>(false);

  const [masterAddr, setMasterAddr] = useState<any>([]);
  const [provinceList, setProvinceList] = useState<any>([]);
  const [districtList, setDistrictList] = useState<any>([]);
  const [subdistrictList, setSubdistrictList] = useState<any>([]);

  const [brand, setBrand] = useState<any>([]);
  const [zone, setZone] = useState<ZoneEntity[]>([]);

  const [selectedShop, setSelectedShop] = useState<StoreEntity[]>([]);
  const [searchShop, setSearchShop] = useState<StoreEntity[]>([]);
  const [showModalShop, setShowModalShop] = useState<boolean>(false);

  const [fileList, setFileList] = useState<any>([]);
  const [fileDelete, setFileDelete] = useState<any>([]);
  const [fileError, setFileError] = useState<boolean>(false);
  const [loadingModal, setLoadingModal] = useState<boolean>(false);

  const getCusComById = async () => {
    form2.setFieldValue("taxNo", taxNo);
    if (isEditing) {
      await getCustomersById(id).then(async (res) => {
        setCurrent(res?.customerType === "DL" ? 1 : 0);
        setImageUrl(res?.profileImage);
        setIsDL(res?.customerType === "DL" ? true : false);
        getAddress(
          res?.customer?.provinceId || 0,
          res?.customer?.district,
          res?.customer?.subdistrict,
        );
        setCenter({ lat: Number(res?.customer.lat), lng: Number(res?.customer.lag) });
        await form2.setFieldsValue({
          customerNo: res?.customerNo,
          zone: res?.zone,
          customerId: res?.customerId,
          title: res?.customer.title,
          brand: res?.productBrand?.map((b) => b.product_brand_name),
          ownerFirstname: res?.customer.ownerFirstname,
          ownerLastname: res?.customer.ownerLastname,
          telephone: res?.customer.telephone,
          email: res?.customer.email,
          nickname: res?.customer.nickname,
          createDate: dayjs(res?.createDate),
          customerName: res?.customerName,
          taxNo: res?.customer.taxNo,
          termPayment: res?.termPayment === "COD" ? res?.termPayment : "N",
          creditDay: res?.termPayment === "COD" ? "" : res?.termPayment.split("N")[1],
          provinceId:
            res?.customer?.provinceId ||
            (await getMasterProvince(res?.customer?.province || "").then((res) => {
              return res?.provinceId || 0;
            })),
          provinceName: res?.customer.province,
          districtId: res?.customer.districtId,
          districtName: res?.customer.district,
          subdistrictId: res?.customer.subdistrictId,
          subdistrictName: res?.customer.subdistrict || "",
          postcode: res?.customer?.postcode,
          address: res?.customer?.address,
          lat: res?.customer?.lat,
          lag: res?.customer?.lag,
          customerCompanyId: res?.customerCompanyId,
          masterAddressId: res?.customer?.masterAddressId,
          customerType: res?.customerType,
          isActive: res?.isActive,
        });
        const files: any = [];
        res?.customerFile.forEach((pic, index) => {
          files.push({
            uid: index + 1,
            status: "done",
            name: pic.dataFile.fileName,
            thumbUrl: pic.url,
            url: pic.url,
            percent: "0",
            customerFileId: pic.dataFile.customerFileId,
          });
        });
        setFileList(files);
        const dataShop: StoreEntity[] = [];
        res?.customerPartner.forEach((shop) => {
          dataShop.push({
            customerCompanyId: shop.customer_company_dealer_id,
            customerId: res.customerId,
            customerName: shop.customer_name,
            customerNo: shop.customer_no,
            isChecked: false,
            zone: shop.zone,
            usedAmount: "",
            balance: "",
          });
        });
        setSelectedShop(dataShop);
      });
    } else {
      id !== "0"
        ? await getCustomersById(id).then(async (res) => {
            setIsDL(res.customer.customerType === "DL" ? true : false);
            let masterAddress: any = "";
            let masterProvince: any = "";
            if (res?.customer?.province) {
              masterProvince = await getMasterProvince(res?.customer?.province).then((res) => res);
              masterAddress = await getAddress(
                masterProvince?.provinceId,
                res?.customer?.district || "",
                res?.customer?.subdistrict || "",
              ).then((res) => res);
            }
            await form2.setFieldsValue({
              customerId: res.customerId,
              title: res.customer.title,
              ownerFirstname: res.customer.ownerFirstname,
              ownerLastname: res.customer.ownerLastname,
              nickname: res.customer.nickname,
              taxNo: taxNo || res.customer.taxNo,
              termPayment: "COD",
              telephone: res.customer.telephone,
              email: res.customer.email,
              createDate: dayjs(res.createDate),
              customerName: res.customerName,
              address: res.customer.address,
              lat: res.customer.lat,
              lag: res.customer.lag,
              provinceId: masterProvince?.provinceId,
              provinceName: masterProvince?.provinceName,
              districtName: masterAddress?.districtId?.districtName || "",
              subdistrictName: masterAddress?.subdistrictId?.subdistrictName || "",
              postcode: masterAddress?.subdistrictId?.postCode,
              isActive: res.isActive || true,
              masterAddress: masterAddress,
            });
          })
        : await form2.setFieldsValue({
            customerType: "SD",
            termPayment: "COD",
            isActive: true,
            taxNo: taxNo,
          });
    }
  };
  const getMasterProvince = async (proNav?: string) => {
    const province = await getProvince().then((res) => {
      return res.responseData;
    });
    setProvinceList(province || []);
    const provinceId = province.find((y: any) => {
      const map = proNav?.endsWith(y.provinceName);
      if (map) {
        return { provinceId: y.provinceId, provinceName: y.provinceName };
      }
    });
    return provinceId;
  };
  const getAddress = async (proId: number, dis: string, sub: string) => {
    const getAddr = await getMasterAddress(proId).then((res) => {
      return res.responseData;
    });
    setMasterAddr(getAddr);
    const groupsDistrict = _.groupBy(getAddr || [], "districtName");
    const objDistrict = _.map(groupsDistrict, (items, disName) => {
      return {
        districtId: items.find((item: any) => item.districtName === disName).districtId,
        districtName: disName,
      };
    });
    setDistrictList(objDistrict);
    const districtId = await objDistrict.find((y: any) => {
      const map = dis?.endsWith(y.districtName);
      if (map) {
        return y.districtId;
      }
    });

    const groupsSubdistrict = _.groupBy(
      getAddr.filter((y: any) => dis?.endsWith(y.districtName)) || [],
      "subdistrictName",
    );
    const objSubdistrict = _.map(groupsSubdistrict, (items, disName) => {
      return {
        masterAddressId: items.find((item: any) => item.subdistrictName === disName)
          .masterAddressId,
        subdistrictId: items.find((item: any) => item.subdistrictName === disName).subdistrictId,
        subdistrictName: disName,
        postCode: items.find((item: any) => item.subdistrictName === disName).postcode,
      };
    });
    setSubdistrictList(objSubdistrict);
    const subdistrictId = await objSubdistrict.find((y: any) => {
      const map = sub.endsWith(y.subdistrictName);
      if (map) {
        return y.subdistrictId;
      }
    });
    return { districtId: districtId, subdistrictId: subdistrictId };
  };
  const getBrand = async () => {
    await getProductBrand(company?.companyCode).then((res) => {
      const mapBrand = res
        .filter((a: any) => a.isActive)
        .map((b) => ({
          company: b.company,
          product_brand_id: b.productBrandId,
          product_brand_logo: b.productBrandLogo,
          product_brand_name: b.productBrandName,
        }));
      setBrand(mapBrand);
    });
  };
  const getZone = async () => {
    await zoneDatasource.getAllZoneByCompany(company?.companyCode).then((res) => {
      setZone(res);
    });
  };

  useEffect(() => {
    getCusComById();
    getMasterProvince();
    getBrand();
    getZone();
  }, []);

  const staticData = [
    {
      label: "คุณ",
      value: "คุณ",
      key: "1",
    },
    {
      label: "นาย",
      value: "นาย",
      key: "2",
    },
    {
      label: "นางสาว",
      value: "นางสาว",
      key: "3",
    },
    {
      label: "นาง",
      value: "นาง",
      key: "4",
    },
  ];
  const onChangeProvince = async () => {
    const payload = form2.getFieldsValue(true);
    const getAddr = await getMasterAddress(payload.provinceId).then((res) => {
      return res.responseData;
    });
    setMasterAddr(getAddr);
    const groupsDistrict = _.groupBy(getAddr || [], "districtName");
    const objDistrict = _.map(groupsDistrict, (items, disName) => {
      return {
        districtId: items.find((item: any) => item.districtName === disName).districtId,
        districtName: disName,
      };
    });
    form2.setFieldsValue({
      districtName: "",
      subdistrictName: "",
      postcode: "",
    });
    setDistrictList(objDistrict);
  };
  const onChangeDistrict = async () => {
    const payload = form2.getFieldsValue(true);
    const groupsSubdistrict = _.groupBy(
      masterAddr.filter((y: any) => payload.districtName.endsWith(y.districtName)) || [],
      "subdistrictName",
    );
    const objSubdistrict = _.map(groupsSubdistrict, (items, disName) => {
      return {
        masterAddressId: items.find((item: any) => item.subdistrictName === disName)
          .masterAddressId,
        subdistrictId: items.find((item: any) => item.subdistrictName === disName).subdistrictId,
        subdistrictName: disName,
        postCode: items.find((item: any) => item.subdistrictName === disName).postcode,
      };
    });
    form2.setFieldsValue({ subdistrictName: "", postcode: "" });
    setSubdistrictList(objSubdistrict);
  };
  const onChangeSubdistrict = async () => {
    const payload = form2.getFieldsValue(true);
    const getSubdistrict = masterAddr.find((x: any) =>
      payload.subdistrictName.endsWith(x.subdistrictName),
    );
    form2.setFieldsValue({
      masterAddressId: getSubdistrict.masterAddressId,
      provinceId: getSubdistrict?.provinceId,
      provinceName: getSubdistrict.provinceName,
      districtId: getSubdistrict.districtId,
      districtName: getSubdistrict.districtName,
      subdistrictId: getSubdistrict.subdistrictId,
      subdistrictName: getSubdistrict.subdistrictName,
      postcode: getSubdistrict ? getSubdistrict.postcode || "" : "",
    });
  };
  const PageTitle = () => {
    return (
      <PageTitleNested
        title={
          isEditing
            ? `แก้ไขร้านค้า ${!isDL ? "Sub-Dealer" : ""}`
            : `เพิ่มร้านค้า ${!isDL ? "Sub-Dealer" : ""}`
        }
        showBack
        extra={
          !isDL && (
            <StepAntd
              current={current}
              items={[
                {
                  title: "ข้อมูลบุคคล/ร้านค้า",
                },
                {
                  title: "ร้านคู่ค้า",
                },
              ]}
            />
          )
        }
        customBreadCrumb={
          <BreadCrumb
            data={[
              { text: "รายชื่อร้านค้า", path: "/ShopManagementPage/CorporateShop" },
              {
                text: isEditing
                  ? `แก้ไขร้านค้า ${!isDL ? "Sub-Dealer" : ""}`
                  : `เพิ่มร้านค้า ${!isDL ? "Sub-Dealer" : ""}`,
                path: window.location.pathname,
              },
            ]}
          />
        }
      />
    );
  };
  const renderStep = () => {
    switch (current) {
      case 0: {
        return StepOne();
      }
      case 1: {
        return StepTwo();
      }
    }
  };
  const sectionUserShop = (
    <>
      <Row gutter={16}>
        <Col>
          <Text fontSize={16} fontWeight={600}>
            รายละเอียดข้อมูลบุคคล (เจ้าของร้าน)
          </Text>
        </Col>
      </Row>
      <Row
        gutter={16}
        style={{
          marginTop: 16,
        }}
      >
        <Col span={6}>
          <Form.Item
            name='title'
            label='คำนำหน้าชื่อ'
            rules={[
              {
                required: true,
                message: "กรุณาเลือกคำนำหน้าชื่อ",
              },
            ]}
          >
            <Selects data={staticData} disabled={isDL} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name='ownerFirstname'
            label='ชื่อจริง'
            rules={[
              {
                required: true,
                message: "กรุณากรอกชื่อจริง",
              },
            ]}
          >
            <Input autoComplete='off' disabled={isDL} style={{ color: color.BK }} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name='ownerLastname'
            label='นามสกุล'
            rules={[
              {
                required: true,
                message: "กรุณากรอกนามสกุล",
              },
            ]}
          >
            <Input autoComplete='off' disabled={isDL} style={{ color: color.BK }} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name='nickname' label='ชื่อเล่น'>
            <Input autoComplete='off' disabled={isDL} style={{ color: color.BK }} />
          </Form.Item>
        </Col>
      </Row>
      <Row
        gutter={16}
        style={{
          marginTop: 16,
        }}
      >
        <Col span={12}>
          <Form.Item
            name='telephone'
            label='เบอร์โทรศัพท์'
            rules={[
              {
                required: true,
                message: "กรุณากรอกเบอร์โทร",
              },
            ]}
          >
            <Input autoComplete='off' maxLength={10} disabled={isDL} style={{ color: color.BK }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name='email' label='อีเมล'>
            <Input autoComplete='off' disabled={isDL} style={{ color: color.BK }} />
          </Form.Item>
        </Col>
      </Row>
      {/* <Row
        gutter={16}
        style={{
          marginTop: 16,
        }}
      >
        <Col span={12}>
          <Form.Item name='createDate' label='วันที่เริ่มเป็นสมาชิก'>
            <DatePicker style={{ width: "100%" }} enablePast />
          </Form.Item>
        </Col>
      </Row> */}
    </>
  );
  const sectionShop = (
    <>
      <Row gutter={16}>
        <Col>
          <Text fontSize={16} fontWeight={600}>
            รายละเอียดข้อมูลร้านค้า
          </Text>
        </Col>
      </Row>
      <Row justify={"space-between"} gutter={16}>
        <Col span={24}>
          <Row>
            <Col span={3}>
              <Image
                src={imageUrl || image.empty_shop}
                style={{
                  width: "136px",
                  height: "136px",
                  borderRadius: "12px",
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row
        justify={"space-between"}
        gutter={16}
        style={{
          marginTop: 16,
        }}
      >
        <Col span={12}>
          <Form.Item name='customerNo' label='รหัสร้านค้า'>
            <Input
              placeholder='ระบุรหัสร้านค้า'
              autoComplete='off'
              disabled={isDL}
              style={{ color: color.BK }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name='zone' label='เขต'>
            <Selects
              allowClear
              placeholder='เลือกเขต'
              data={
                zone.map((z) => ({
                  label: z.zoneName,
                  key: z.zoneId,
                  value: z.zoneName,
                })) || []
              }
              disabled={isDL}
              style={{ color: color.BK }}
            />
          </Form.Item>
        </Col>
      </Row>
      {isDL && (
        <Row justify={"space-between"} gutter={16}>
          <Col span={12}>
            <Form.Item name='brand' label='แบรนด์สินค้า (Product Brands)'>
              <Selects
                mode='multiple'
                placeholder='เลือก Product Brands'
                data={
                  brand.map((z) => ({
                    label: z.product_brand_name,
                    key: z.product_brand_id,
                    value: z.product_brand_name,
                  })) || []
                }
              />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row
        gutter={16}
        style={{
          marginTop: 16,
        }}
      >
        <Col span={12}>
          <Form.Item
            name='customerName'
            label='ชื่อร้านค้า'
            rules={[
              {
                required: true,
                message: "กรุณากรอกชื่อร้านค้า",
              },
            ]}
          >
            <Input autoComplete='off' disabled={isDL} style={{ color: color.BK }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name='taxNo'
            label='หมายเลขนิติบุคคล'
            rules={[
              {
                required: true,
                message: "กรุณากรอกหมายเลขนิติบุคคล",
              },
              // {
              //   pattern: /^[0-9]*$/,
              //   message: "กรุณากรอกตัวเลขเท่านั้น",
              // },
            ]}
          >
            <Input autoComplete='off' maxLength={13} disabled={isDL} style={{ color: color.BK }} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name='termPayment'
            label='รูปแบบการชำระ'
            rules={[
              {
                required: true,
                message: "*โปรดระบุรูปแบบการชำระ",
              },
            ]}
          >
            <Radio.Group
              style={{ width: "100%", color: color.BK }}
              onChange={() => {
                form2.setFieldValue("creditDay", "");
              }}
              disabled={isDL}
            >
              <Space direction='vertical' style={{ width: "100%", color: color.BK }}>
                <Radio value='COD'>เงินสด</Radio>
                <Row gutter={8} style={{ width: "100%" }}>
                  <Col>
                    <Radio value='N'>เครดิต</Radio>
                  </Col>
                  <Col>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prev, current) => {
                        return prev.termPayment !== current.termPayment;
                      }}
                      rules={[
                        {
                          required: true,
                          message: "*โปรดระบุจำนวนวัน",
                        },
                      ]}
                    >
                      {({ getFieldValue }) => {
                        return (
                          getFieldValue("termPayment") === "N" && (
                            <Col>
                              <Form.Item
                                noStyle
                                name='creditDay'
                                rules={[
                                  {
                                    required: true,
                                    message: "*โปรดระบุจำนวนวัน",
                                  },
                                ]}
                              >
                                <Input
                                  suffix='วัน'
                                  autoComplete='off'
                                  disabled={isDL}
                                  style={{ color: color.BK }}
                                />
                              </Form.Item>
                            </Col>
                          )
                        );
                      }}
                    </Form.Item>
                  </Col>
                </Row>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col>
          <Text fontSize={16} fontWeight={600}>
            ที่อยู่ร้านค้า
          </Text>
        </Col>
      </Row>
      <Row
        gutter={16}
        style={{
          marginTop: 16,
        }}
      >
        <Col span={6}>
          <Form.Item
            name='provinceId'
            label='จังหวัด'
            rules={[
              {
                required: isDL ? false : true,
                message: "กรุณาเลือกจังหวัด",
              },
            ]}
          >
            <AntSelectCustom
              disabled={isDL}
              showSearch
              allowClear
              options={
                provinceList?.map((x: any) => ({
                  key: x.provinceName,
                  value: x.provinceId,
                  label: x.provinceName,
                })) || []
              }
              onChange={onChangeProvince}
              optionFilterProp='children'
              filterOption={(input: any, option: any) => {
                return (option?.key ?? "").includes(input);
              }}
              filterSort={(optionA: any, optionB: any) => {
                return (optionA?.key ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.key ?? "").toLowerCase());
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name='districtName'
            label='อำเภอ/เขต'
            rules={[
              {
                required: isDL ? false : true,
                message: "กรุณาเลือกอำเภอ/เขต",
              },
            ]}
          >
            <AntSelectCustom
              disabled={isDL}
              allowClear
              showSearch
              options={
                districtList?.map((x: any) => ({
                  key: x.districtName,
                  value: x.districtName,
                  label: x.districtName,
                })) || []
              }
              onChange={onChangeDistrict}
              optionFilterProp='children'
              filterOption={(input: any, option: any) => {
                return (option?.key ?? "").includes(input);
              }}
              filterSort={(optionA: any, optionB: any) => {
                return (optionA?.key ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.key ?? "").toLowerCase());
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name='subdistrictName'
            label='ตำบล/แขวง'
            rules={[
              {
                required: isDL ? false : true,
                message: "กรุณาเลือกตำบล/แขวง",
              },
            ]}
          >
            <AntSelectCustom
              disabled={isDL}
              allowClear
              showSearch
              options={
                subdistrictList?.map((x: any) => ({
                  key: x.subdistrictName,
                  value: x.subdistrictName,
                  label: x.subdistrictName,
                })) || []
              }
              onChange={onChangeSubdistrict}
              optionFilterProp='children'
              filterOption={(input: any, option: any) => {
                return (option?.key ?? "").includes(input);
              }}
              filterSort={(optionA: any, optionB: any) => {
                return (optionA?.key ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.key ?? "").toLowerCase());
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name='postcode'
            label='รหัสไปรษณีย์'
            rules={[
              {
                required: isDL ? false : true,
                message: "กรุณาเลือกรหัสไปรษณีย์",
              },
            ]}
          >
            <Input autoComplete='off' disabled={isDL} style={{ color: color.BK }} />
          </Form.Item>
        </Col>
      </Row>
      <Row
        gutter={16}
        style={{
          marginTop: 16,
        }}
      >
        <Col span={12}>
          <Form.Item
            name='address'
            label='ที่อยู่ (บ้านเลขที่ หมู่ ซอย ชั้น อาคาร)'
            rules={[
              {
                required: isDL ? false : true,
                message: "กรุณากรอกที่อยู่",
              },
            ]}
          >
            <Input autoComplete='off' disabled={isDL} style={{ color: color.BK }} />
          </Form.Item>
        </Col>
      </Row>
      {isDL && (
        <Row
          gutter={16}
          style={{
            marginTop: 16,
          }}
        >
          <Col span={12}>
            <Form.Item
              name='lat'
              label='ตำแหน่ง Latitude'
              rules={[
                {
                  required: isDL ? false : true,
                  message: "กรุณากรอก Latitude",
                },
                {
                  pattern: /^[0-9.]*$/,
                  message: "กรุณากรอกตัวเลขและจุด (.)เท่านั้น",
                },
              ]}
            >
              <Input
                autoComplete='off'
                style={{ color: color.BK }}
                onPressEnter={() =>
                  setCenter({
                    lat: Number(form2.getFieldValue("lat")),
                    lng: Number(form2.getFieldValue("lag")),
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='lag'
              label='ตำแหน่ง Longitude'
              rules={[
                {
                  required: isDL ? false : true,
                  message: "กรุณากรอก Longitude",
                },
                {
                  pattern: /^[0-9.]*$/,
                  message: "กรุณากรอกตัวเลขและจุด (.)เท่านั้น",
                },
              ]}
            >
              <Input
                autoComplete='off'
                style={{ color: color.BK }}
                onPressEnter={() =>
                  setCenter({
                    lat: Number(form2.getFieldValue("lat")),
                    lng: Number(form2.getFieldValue("lag")),
                  })
                }
              />
            </Form.Item>
          </Col>
        </Row>
      )}
      {!isDL && (
        <>
          <Row gutter={16}>
            <Col>
              <Text fontSize={16} fontWeight={600}>
                ใบอนุญาตขายปุ๋ย
              </Text>{" "}
              <Text fontSize={16} fontWeight={400} color='Text3'>
                (ไฟล์ภาพ JPG/PNG, PDF)
              </Text>
              <br />
              {fileError && (
                <Text fontSize={14} fontWeight={400} color='error'>
                  ไม่รองรับนามสกุลไฟล์ที่อัปโหลด
                </Text>
              )}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Upload
                listType='picture'
                fileList={fileList}
                beforeUpload={(file) => {
                  const isExcel =
                    file.type ===
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                    file.type === "text/csv";
                  if (isExcel) {
                    setFileError(true);
                    return Upload.LIST_IGNORE;
                  }
                  setFileError(false);
                  return false;
                }}
                onChange={(file: any) => {
                  setTimeout(() => {
                    file.percent = "100";
                    file.status = "done";
                  }, 250);
                  if (file.file.status !== "removed") {
                    if (file.file.type === "application/pdf") {
                      const payload: any = {};
                      payload.customerFileId = file.customerFileId || "";
                      payload.uid = fileList.length + 1;
                      payload.status = "done";
                      payload.name = file.file.name;
                      payload.file = file.file;

                      const fileBlob = new Blob([(file as any).file], {
                        type: "application/pdf",
                      });
                      const fileURL = URL.createObjectURL(fileBlob);
                      payload.thumbUrl = fileURL;
                      payload.url = fileURL;
                      setFileList([...fileList, payload]);
                    } else {
                      const payload: any = {};
                      payload.customerFileId = file.customerFileId || "";
                      payload.uid = fileList.length + 1;
                      payload.status = "done";
                      payload.name = file.file.name;
                      payload.file = file.file;

                      const reader = new FileReader();
                      reader.onload = (e) => {
                        payload.thumbUrl = e.target?.result as string;
                        payload.url = e.target?.result as string;
                      };
                      reader.readAsDataURL(file.file);
                      setFileList([...fileList, payload]);
                    }
                  }
                }}
                customRequest={(file: any) => {
                  return file;
                }}
                onRemove={(file: any) => {
                  setFileDelete([...fileDelete, file]);
                  setFileList(fileList.filter((x) => x.uid !== file.uid));
                }}
              >
                <Button
                  icon={<UploadOutlined style={{ fontSize: "20px" }} />}
                  title='อัปโหลดไฟล์'
                />
              </Upload>
            </Col>
          </Row>
          <br />
          {isEditing && (
            <Row>
              <Col>
                <Form.Item name='isActive' label='สถานะ'>
                  <Radio.Group>
                    <Space direction='vertical'>
                      <Radio value={true}>เปิดการใช้งาน</Radio>
                      <Radio value={false}>ปิดการใช้งาน</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          )}
        </>
      )}
    </>
  );
  const StepOne = () => {
    return (
      <Form form={form2} layout='vertical'>
        {sectionUserShop}
        <br />
        {sectionShop}
        {isDL ? (
          <GoogleMaps
            isEdit={true}
            changeLatLng={(lat, lng) => {
              form2.setFieldsValue({
                lat: Number(lat),
                lng: Number(lng),
              });
            }}
            width='100%'
            height='300px'
            zoom={17}
            lat={center.lat}
            lng={center.lng}
          />
        ) : (
          <></>
        )}
        <br />
      </Form>
    );
  };
  const StepTwo = () => {
    const callBackShop = (item: StoreEntity[]) => {
      item = item.map((p: any) => ({ ...p, isChecked: false }));
      setSelectedShop([...selectedShop, ...item]);
      setSearchShop([...selectedShop, ...item]);
      setShowModalShop(!showModalShop);
    };
    const handleCheckBoxDelete = (e: any, cusId: string) => {
      const checkBoxed = selectedShop.map((item) =>
        _.set(
          item,
          "isChecked",
          item.customerCompanyId === cusId ? e.target.checked : item.isChecked,
        ),
      );
      setSelectedShop(checkBoxed);
      setSearchShop(checkBoxed);
    };
    const handleAllCheckBoxDelete = (e: any) => {
      const checkBoxed = selectedShop.map((item) => ({ ...item, isChecked: e.target.checked }));
      const mapData = searchShop.map((item) => {
        const findObj = checkBoxed.find((el) => el.customerCompanyId === item.customerCompanyId);
        if (findObj) {
          return { ...item, isChecked: true };
        }
        return { ...item, isChecked: false };
      });
      setSelectedShop(checkBoxed);
      setSearchShop(mapData);
    };
    const handleDelete = () => {
      const deleted = selectedShop.filter((x) => !x.isChecked);
      setSelectedShop(deleted);
      setSearchShop(deleted);
    };
    const columeTableShop = [
      {
        title: selectedShop.length > 0 && (
          <Checkbox
            onClick={(e) => handleAllCheckBoxDelete(e)}
            checked={selectedShop.every((x) => x.isChecked)}
          />
        ),
        width: "5%",
        render: (text: string, value: any) => (
          <Checkbox
            onClick={(e) => handleCheckBoxDelete(e, value.customerCompanyId)}
            checked={value.isChecked}
          />
        ),
      },
      {
        title: <center>Customer No.</center>,
        dataIndex: "customerNo",
        width: "15%",
        render: (text: string) => <center>{text}</center>,
      },
      {
        title: <center>ชื่อร้านค้า</center>,
        dataIndex: "customerName",
        width: "65%",
        render: (text: string) => <center>{text}</center>,
      },
      {
        title: <center>เขตการขาย</center>,
        dataIndex: "zone",
        render: (text: string) => <center>{text}</center>,
      },
    ];
    return (
      <>
        <Row>
          <Col span={19}>
            <Text level={5} fontWeight={700}>
              รายการเพิ่มคู่ค้า Dealer*
            </Text>{" "}
            <Text level={5} color='Text3'>
              เลือกคู่ค้าได้มากสุด 3 ร้าน
            </Text>
          </Col>
          <Col span={2} style={{ paddingRight: "6px" }}>
            {selectedShop.filter((x) => x.isChecked).length > 0 && (
              <FlexRow align='center' justify='end' style={{ height: "100%" }}>
                <DeleteOutlined
                  style={{ fontSize: 20, color: color.error }}
                  onClick={handleDelete}
                />
              </FlexRow>
            )}
          </Col>
          <Col span={3}>
            <Button title='+ เพิ่มคู่ค้า Dealer' onClick={() => setShowModalShop(!showModalShop)} />
          </Col>
        </Row>
        <br />
        <TableContainer>
          <Table
            columns={columeTableShop}
            dataSource={selectedShop || []}
            size='large'
            tableLayout='fixed'
            pagination={false}
            scroll={{ y: 500 }}
          />
        </TableContainer>
        {showModalShop && (
          <ModalSelectStore
            company={company?.companyCode}
            callBackShop={callBackShop}
            showModalShop={showModalShop}
            onClose={() => setShowModalShop(!setShowModalShop)}
            currentSelectShop={selectedShop}
            limitSelected={3}
          />
        )}
      </>
    );
  };
  const footer = () => {
    return (
      <Row justify='space-between' gutter={12}>
        <Col xl={3} sm={6}>
          {current > 0 && !isDL && (
            <Button
              typeButton='primary-light'
              title='ย้อนกลับ'
              onClick={() => setCurrent(current - 1)}
            />
          )}
        </Col>
        <Col xl={15} sm={6}></Col>
        <Col xl={3} sm={6}>
          <Button
            typeButton='primary'
            title={current === 1 ? "บันทึก" : "ถัดไป"}
            onClick={() => {
              nextStep();
            }}
          />
        </Col>
      </Row>
    );
  };
  const nextStep = async () => {
    if (current === 0) {
      await form2
        .validateFields()
        .then((f2) => {
          setCurrent(current + 1);
        })
        .catch((errInfo) => {
          console.log("form1 errInfo", errInfo);
        });
    } else {
      form2
        .validateFields()
        .then(async (f) => {
          setLoadingModal(true);
          const f2 = form2.getFieldsValue(true);
          const payload: any = {};
          const cusCom: any = {};
          const dealer: any = [];
          if (isDL) {
            const jsonBrand = f2.brand.map((x) => {
              const find = brand.find((y) => y.product_brand_name === x);
              if (find) {
                return find;
              }
            });
            cusCom.productBrand = jsonBrand;
          }

          cusCom.customerId = f2.customerId;
          cusCom.isNav = id !== "0" ? true : false;
          cusCom.customerName = f2.customerName;
          cusCom.customerNo = f2.customerNo;
          cusCom.company = company.companyCode;
          cusCom.customerType = isDL ? "DL" : "SD";
          cusCom.zone = f2.zone;
          cusCom.termPayment =
            f2.termPayment === "N" ? f2.termPayment + f2.creditDay : f2.termPayment;
          cusCom.creditLimit = 0;
          cusCom.isActive = f2.isActive;
          cusCom.salePersonCode = "";
          cusCom.updateBy = userProfile.firstname + " " + userProfile.lastname;

          payload.customerId = f2.customerId;
          payload.address = f2.address;
          payload.masterAddressId =
            f2.masterAddress?.subdistrictId?.masterAddressId || f2.masterAddressId;
          payload.provinceId = f2?.provinceId;
          payload.districtId = f2?.masterAddress?.districtId?.districtId || f2?.districtId;
          payload.subdistrictId =
            f2?.masterAddress?.subdistrictId?.subdistrictId || f2?.subdistrictId;
          payload.province = f2?.provinceName;
          payload.district = f2?.districtName;
          payload.subdistrict = f2?.subdistrictName;
          payload.postcode = f2?.postcode;
          payload.telephone = f2?.telephone;
          payload.taxNo = f2.taxNo;
          payload.updateBy = userProfile.firstname + " " + userProfile.lastname;
          payload.lat = f2.lat;
          payload.lag = f2.lag;
          payload.statusMainTel = true;
          payload.statusSecondTel = true;
          payload.title = f2.title;
          payload.ownerFirstname = f2.ownerFirstname;
          payload.ownerLastname = f2.ownerLastname;
          payload.email = f2.email;
          payload.nickname = f2.nickname;

          if (selectedShop.length) {
            selectedShop.forEach((shop) => {
              if (isEditing) {
                dealer.push({
                  customerCompanyDealerId: Number(shop.customerCompanyId),
                  customerCompanySubdealerId: id,
                });
              } else {
                dealer.push({
                  customerCompanyDealerId: Number(shop.customerCompanyId),
                });
              }
            });
            payload.customerPartner = dealer;
          }

          if (isEditing) {
            cusCom.customerCompanyId = f2.customerCompanyId;
            payload.customerCompany = cusCom;
            await updateCustomerEx(payload).then((res) => {
              if (res.success) {
                setTimeout(() => {
                  fileList
                    .filter((x) => !x.customerFileId)
                    .forEach(async (file, index) => {
                      const pic = new FormData();
                      pic.append("customerId", form2.getFieldValue("customerId"));
                      pic.append("createBy", `${userProfile.firstname} ${userProfile.lastname}`);
                      pic.append("orderFile", index + 1);
                      pic.append("file", file.file);
                      await uploadFile(pic).then((res) => res);
                    });
                  setLoadingModal(false);
                  navigate("/ShopManagementPage/CorporateShop");
                }, 600);
              }
            });
          } else {
            payload.customerCompany = cusCom;
            await createCustomerEx(payload).then((res) => {
              if (res.success) {
                setTimeout(() => {
                  fileList
                    .filter((x) => !x.customerFileId)
                    .forEach(async (file, index) => {
                      const pic = new FormData();
                      pic.append("customerId", res.responseData.customerId);
                      pic.append("createBy", `${userProfile.firstname} ${userProfile.lastname}`);
                      pic.append("orderFile", index + 1);
                      pic.append("file", file.file);
                      await uploadFile(pic).then((res) => res);
                    });
                  setLoadingModal(false);
                  navigate("/ShopManagementPage/CorporateShop");
                }, 600);
              }
            });
          }
        })
        .catch((errInfo) => {
          console.log("form2 errInfo", errInfo);
        });

      if (fileDelete.length) {
        fileDelete
          .filter((x) => x.customerFileId)
          .forEach(async (file, index) => {
            await deleteFile(file.customerFileId).then((res) => res);
          });
      }
    }
  };

  return (
    <>
      <CardContainer>
        <PageTitle />
        <Divider />
        {!isDL && <>{renderStep()}</>}
        {isDL && <StepOne />}
        <br />
        {footer()}
      </CardContainer>
      <Modal open={loadingModal} footer={null} width={220} closable={false}>
        <FlexCol align='space-around' justify='center' style={{ width: 172, height: 172 }}>
          <CheckCircleTwoTone twoToneColor={color.success} style={{ fontSize: 36 }} />
          <br />
          <Text level={4} align='center'>
            {isEditing ? "แก้ไข" : "สร้าง"}ร้านค้า
            <br />
            สำเร็จ
          </Text>
        </FlexCol>
      </Modal>
    </>
  );
};
