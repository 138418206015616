import React from "react";
import color from "../../resource/color";

export const ActionBtn = ({ onClick, icon }: any) => {
  return (
    <div className='btn btn-icon btn-light btn-hover-primary btn-sm' onClick={onClick}>
      <span className='svg-icon svg-icon-primary svg-icon-2x' style={{ color: color["primary"] }}>
        {icon}
      </span>
    </div>
  );
};
