import { Col, Modal, Pagination, Row, Tabs } from "antd";
import Text from "../../Text/Text";
import MinMaxInput from "../../Input/MinMaxInput";
import styled from "styled-components";
import color from "../../../resource/color";
import ExpandTable from "../../Table/ExpandTable";
import icon from "../../../resource/icon";
import { useEffect, useState } from "react";
import useSorting from "../../../hook/useSorting";
import SortFieldCustom from "../../SortFieldTable/SortFieldCustom";
import { reportPointShop } from "../../../datasource/ReportCRMPointDatasource";
import moment from "moment";
import { allCrmPointShopEntities } from "../../../entities/ReportPointShopEntity";
import { numberFormatter } from "../../../utility/Formatter";
import { getDateRange } from "../../../utility/dateUtils";

interface Props {
  visible: boolean;
  onCancel: () => void;
  filterTarget: string[];
  filterDay: {
    startDate: Date;
    endDate: Date;
  } | null;
  campaignProgramCondition: string[];
  campaignProgramType: string[];
  campaignId: string;
  countCampaign: number;
  type: string;
}
const BaseText = styled.div`
  font-family: "Sarabun";
  font-size: 16px;
  color: ${color.Text1};
  font-weight: 400;
`;

function ModalShopList({
  visible,
  onCancel,
  filterTarget,
  filterDay,
  campaignProgramCondition,
  campaignProgramType,
  campaignId,
  countCampaign,
  type,
}: Props) {
  const userProfile = JSON.parse(localStorage.getItem("profile")!);
  const [isMin, setIsMin] = useState<number | undefined>(undefined);
  const [isMax, setIsMax] = useState<number | undefined>(undefined);
  const { sortField, sortDirection, handleSortChange } = useSorting();
  const [data, setData] = useState<allCrmPointShopEntities>();
  const [loading, setLoading] = useState(false);
  const [userType, setUserType] = useState<string>(
    campaignProgramType?.includes("ONLY_SUB_DEALER") ? "SUB_DEALER" : "DEALER",
  );
  const [page, setPage] = useState<number>(1);
  const [take, setTake] = useState<number>(10);
  useEffect(() => {
    if (campaignProgramType.includes("ONLY_SUB_DEALER")) {
      setUserType("SUB_DEALER");
    } else {
      setUserType("DEALER");
    }
  }, [campaignProgramType]);

  useEffect(() => {
    if (visible) {
      fetchPointShop();
    }
  }, [visible, userType, sortDirection, isMax, isMin, page]);

  const fetchPointShop = async () => {
    setLoading(true);
    try {
      const { startDate, endDate } = getDateRange(type, filterDay);
      const value = {
        company: userProfile?.company,
        startDate: startDate,
        endDate: endDate,
        campaignId: campaignId,
        objective: filterTarget,
        campaignProgramType: campaignProgramType,
        campaignProgramCondition: campaignProgramCondition,
        userType: userType,
        minQuantity: isMin === 0 ? undefined : isMin,
        maxQuantity: isMax === 0 ? undefined : isMax,
        sortBy: sortDirection ? sortField : undefined,
        sortDirection: sortDirection,
        page: page,
        take: take,
      };
      await reportPointShop(value).then((res) => {
        setData(res);
        setLoading(false);
      });
    } catch (error) {
      console.log("err report-crm-point-shop", error);
    } finally {
      setLoading(false);
    }
  };

  const handleValuesChange = (newMin: number | undefined, newMax: number | undefined) => {
    setIsMin(newMin);
    setIsMax(newMax);
  };
  const isSingleType = campaignProgramType.length === 1;
  const dealerCount = numberFormatter(data?.summary.dealer || 0, 0);
  const subDealerCount = numberFormatter(data?.summary.subDealer || 0, 0);

  const getTabLabel = (label: string, count: string) => (
    <Text fontSize={14} fontWeight={700}>
      {`${label} (${count})`}
    </Text>
  );
  const tabsItems = isSingleType
    ? [
        {
          label: getTabLabel(
            campaignProgramType[0] === "ONLY_SUB_DEALER" ? "Sub-Dealer" : "Dealer",
            campaignProgramType[0] === "ONLY_SUB_DEALER" ? subDealerCount : dealerCount,
          ),
          key: campaignProgramType[0] === "ONLY_SUB_DEALER" ? "SUB_DEALER" : "DEALER",
        },
      ]
    : [
        { label: getTabLabel("Dealer", dealerCount), key: "DEALER" },
        { label: getTabLabel("Sub-Dealer", subDealerCount), key: "SUB_DEALER" },
      ];

  const handlePageChange = (page: number, take: number) => {
    setPage(page);
    setTake(take);
  };
  const column: any = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",
      width: "5%",
      render: (value: any, row: any, index: number) => {
        return {
          children: <>{index + 1}</>,
        };
      },
    },
    {
      title: "รหัสร้าน",
      dataIndex: "cusComNo",
      key: "cusComNo",
      width: "8%",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <BaseText>{value}</BaseText>
            </>
          ),
        };
      },
    },
    {
      title: "ชื่อร้านค้า",
      dataIndex: "cusComName",
      key: "cusComName",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <BaseText>{value}</BaseText>
            </>
          ),
        };
      },
    },
    {
      title: "เขต",
      dataIndex: "cusComZone",
      key: "cusComZone",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <BaseText>{value}</BaseText>
            </>
          ),
        };
      },
    },
    {
      title: () => (
        <SortFieldCustom
          title='คำสั่งซื้อ'
          sortDirection={sortField === "countOrder" ? sortDirection : undefined}
          onSortChange={() => handleSortChange("countOrder")}
        />
      ),
      dataIndex: "countOrder",
      key: "countOrder",
      width: "10%",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <BaseText>{numberFormatter(value, 0)}</BaseText>
            </>
          ),
        };
      },
    },
  ];
  if (userProfile?.company === "ICPL") {
    column.push(
      {
        title: () => (
          <SortFieldCustom
            title='ปริมาณที่สั่งซื้อ (ลัง)'
            sortDirection={sortField === "sumQuantity" ? sortDirection : undefined}
            onSortChange={() => handleSortChange("sumQuantity")}
          />
        ),
        dataIndex: "sumQuantity",
        key: "sumQuantity",
        render: (value: any, row: any, index: number) => {
          return {
            children: (
              <>
                <BaseText>{numberFormatter(value, 2)}</BaseText>
              </>
            ),
          };
        },
      },
      {
        title: () => (
          <SortFieldCustom
            title='ปริมาณที่สั่งซื้อ (กระสอบ)'
            sortDirection={sortField === "sumQuantity" ? sortDirection : undefined}
            onSortChange={() => handleSortChange("sumQuantity")}
          />
        ),
        dataIndex: "sumQuantity",
        key: "sumQuantity",
        render: (value: any, row: any, index: number) => {
          return {
            children: (
              <>
                <BaseText>{numberFormatter(value, 2)}</BaseText>
              </>
            ),
          };
        },
      },
    );
  }

  column.push({
    title: () => (
      <SortFieldCustom
        title='ปริมาณที่สั่งซื้อ (ตัน)'
        sortDirection={sortField === "sumQuantity" ? sortDirection : undefined}
        onSortChange={() => handleSortChange("sumQuantity")}
      />
    ),
    dataIndex: "sumQuantity",
    key: "sumQuantity",
    width: "18%",
    render: (value: any, row: any, index: number) => {
      return {
        children: (
          <>
            <BaseText>{numberFormatter(value, 2)}</BaseText>
          </>
        ),
      };
    },
  });

  column.push({
    title: () => (
      <SortFieldCustom
        title='แต้มที่ได้รับ (แต้ม)'
        sortDirection={sortField === "sumPoint" ? sortDirection : undefined}
        onSortChange={() => handleSortChange("sumPoint")}
      />
    ),
    dataIndex: "sumPoint",
    key: "sumPoint",
    width: "16%",
    render: (value: any, row: any, index: number) => {
      return {
        children: (
          <>
            <BaseText>{numberFormatter(value, 2)}</BaseText>
          </>
        ),
      };
    },
  });
  const handleClose = () => {
    setIsMin(undefined);
    setIsMax(undefined);
    onCancel();
  };
  return (
    <Modal
      open={visible}
      onCancel={handleClose}
      maskClosable={false}
      width={1200}
      footer={false}
      centered
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          padding: "0px 0px 22px 0px",
        }}
      >
        <Text fontSize={20} fontWeight={700}>
          ร้านค้า
        </Text>
        <Text fontSize={14} fontWeight={400} fontFamily='Sarabun' color='grayishBlue'>
          ทั้งหมด&nbsp;
          {numberFormatter(data?.summary.dealer || 0, 0) +
            numberFormatter(data?.summary.subDealer || 0, 0)}{" "}
          ร้าน จาก&nbsp;
          {numberFormatter(countCampaign, 0)} แคมเปญ (ไม่นับรวมรายชื่อร้านที่ซ้ำกัน)
        </Text>
      </div>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className='gutter-row' span={18}>
          <Tabs
            items={tabsItems}
            onChange={(key: string) => {
              setUserType(key);
            }}
            defaultValue={userType}
          />
        </Col>
        <Col className='gutter-row' span={6} style={{ left: "2%" }}>
          <MinMaxInput
            initialMin={isMin}
            initialMax={isMax}
            onChange={(min, max) => handleValuesChange(min, max)}
          />
        </Col>
      </Row>
      <ExpandTable
        loading={loading}
        columns={column}
        data={data?.data}
        textEmpty='ไม่มีรายการ'
        imgEmpty={icon.emptyHistoryPoint}
        scrollY={400}
      />
      <div style={{ padding: "22px  0px 22px 0px", display: "flex", justifyContent: "end" }}>
        <Pagination
          current={page}
          total={data?.summary.dealer}
          onChange={(pageNumber) => handlePageChange(pageNumber, take)}
          pageSize={take}
          showSizeChanger={false}
        />
      </div>
    </Modal>
  );
}

export default ModalShopList;
