import React, { useEffect, useState } from "react";
import { CardContainer } from "../../../components/Card/CardContainer";
import { Col, Row, Tabs } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { RangePicker } from "../../../components/DatePicker/DatePicker";
import Input from "../../../components/Input/Input";
import TablePagination from "../../../components/Table/TablePagination";
import Text from "../../../components/Text/Text";
import TabPane from "antd/es/tabs/TabPane";
import {
  MAP_STATUS_STOCK,
  STOCK_STATUS_COLOR,
  STOCK_STATUS_WORD,
} from "../../../definitions/stockSetting";
import { useNavigate } from "react-router-dom";
import icon from "../../../resource/icon";
import { BaseText, DescText } from "../../DODocumentPage";
import StatusBadge from "../../../components/Badge/StatusBadge";
import { getCampaignStock } from "../../../datasource/StockDatasource";
import {
  AllCampaignStockEntities,
  AllCampaignStockEntities_INIT,
} from "../../../entities/CampaignStockEntity";
import moment from "moment";

function StockSettingList() {
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const [dateFilter, setDateFilter] = useState<any>();
  const [tabCounts, setTabCounts] = useState<Record<string, number>>({});
  const [isTabs, setIsTabs] = useState<string | "ALL">("ALL");
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState(false);
  const userProfile = JSON.parse(localStorage.getItem("profile")!);
  const [data, setData] = useState<AllCampaignStockEntities>(AllCampaignStockEntities_INIT);

  useEffect(() => {
    getAllCampaignStock();
  }, [isTabs, searchText, dateFilter, page]);

  const getAllCampaignStock = async () => {
    setLoading(true);
    try {
      await getCampaignStock(
        userProfile?.company,
        isTabs,
        page,
        10,
        searchText,
        dateFilter && dateFilter[0] ? dateFilter[0].format("YYYY-MM-DD 00:00:00") : undefined,
        dateFilter && dateFilter[1] ? dateFilter[1].format("YYYY-MM-DD 23:59:59") : undefined,
      ).then((res) => {
        setData(res);
        setTabCounts(res.statusCount);
      });
    } catch (error) {
      console.log("err campaign stock", error);
    } finally {
      setLoading(false);
    }
  };

  const PageTitle = () => {
    return (
      <Row justify='start'>
        <Col className='gutter-row' span={12}>
          <div>
            <span
              className='card-label font-weight-bolder text-dark'
              style={{ fontSize: 20, fontWeight: "700" }}
            >
              ตั้งค่าสต๊อคร้านค้า Dealer
            </span>
          </div>
        </Col>
        <Col span={12}>
          <Row justify='end' gutter={{ xs: 8, sm: 16 }}>
            <Col className='gutter-row' span={12}>
              <Input
                placeholder='รหัส/ชื่อแคมเปญ'
                suffix={<SearchOutlined />}
                defaultValue={searchText}
                onPressEnter={(e) => {
                  const value = (e.target as HTMLTextAreaElement).value;
                  setSearchText(value);
                  setPage(1);
                }}
                onChange={(e) => {
                  const value = (e.target as HTMLInputElement).value;
                  if (!value) {
                    setSearchText(undefined);
                  }
                }}
              />
            </Col>
            <Col className='gutter-row' span={12}>
              <RangePicker
                allowEmpty={[true, true]}
                enablePast
                value={dateFilter}
                onChange={(dates, dateString) => {
                  setDateFilter(dates);
                  setPage(1);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const handleTabChange = (val: string) => {
    setIsTabs(val);
    setPage(1);
  };
  const columns: any[] = [
    {
      title: "ชื่อแคมเปญ",
      dataIndex: "campaignName",
      key: "campaignName",
      width: "28%",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <BaseText>{value}</BaseText>
              <DescText>{row.campaignCode}</DescText>
            </>
          ),
        };
      },
    },
    {
      title: "ระยะเวลา",
      dataIndex: "time",
      key: "time",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <BaseText>
              {moment(row.startDate).format("DD/MM/YYYY") +
                "-" +
                moment(row.endDate).format("DD/MM/YYYY")}
            </BaseText>
          ),
        };
      },
    },
    {
      title: "Dealer ที่ตั้งค่าสต๊อคแล้ว",
      dataIndex: "dealerName",
      key: "dealerName",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <BaseText>{(row.countDealer || 0) + "/" + (row.countDealerAll || 0)}</BaseText>
              <DescText>ร้าน</DescText>
            </>
          ),
        };
      },
    },
    {
      title: "อัปเดตโดย",
      dataIndex: "updateAt",
      key: "updateAt",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <BaseText>{moment(row.updateAt).format("DD/MM/YYYY")}</BaseText>
              <DescText>{row.updateBy}</DescText>
            </>
          ),
        };
      },
    },
    {
      title: "สถานะแคมเปญ",
      dataIndex: "status",
      key: "status",
      render: (value: any, row: any) => {
        return {
          children: (
            <>
              {row.status === "EXPIRED" ? (
                <Text fontFamily='IBM Plex Sans Thai' fontSize={14} fontWeight={400} color='Text3'>
                  หมดอายุ
                </Text>
              ) : (
                <StatusBadge label={STOCK_STATUS_WORD[value]} bgColor={STOCK_STATUS_COLOR[value]} />
              )}
            </>
          ),
        };
      },
    },

    {
      title: "จัดการ",
      dataIndex: "action",
      key: "action",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <div className='btn btn-icon btn-light btn-hover-primary btn-sm'>
                <img
                  src={icon.writeIcon}
                  style={{ width: "20px", height: "20px" }}
                  onClick={() => navigate("/DetectOrderPage/stockSetting/edit/" + row.stockId)}
                />
              </div>
            </>
          ),
        };
      },
    },
  ];

  return (
    <CardContainer>
      <PageTitle />

      <div style={{ marginTop: 22 }}>
        <Tabs onChange={handleTabChange}>
          {MAP_STATUS_STOCK.map((tab) => (
            <TabPane
              tab={
                <Text fontSize={14} fontWeight={400}>
                  {tab.label} ({tabCounts[tab.value] || 0})
                </Text>
              }
              key={tab.key}
            />
          ))}
        </Tabs>
        <TablePagination
          isLoading={loading}
          locale={{
            emptyText: (
              <div
                style={{
                  padding: 32,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 24,
                  minHeight: 350,
                }}
              >
                <img
                  src={icon.emptyHistoryPoint}
                  style={{
                    width: 80,
                    height: 90,
                  }}
                  alt='empty_table'
                />
                <Text fontFamily='Sarabun' fontSize={14} fontWeight={400} color='Text3'>
                  ไม่มีรายการแคมเปญ
                </Text>
              </div>
            ),
          }}
          columns={columns}
          data={
            (data.data || []).map((el: any, idx: number) => {
              return {
                ...el,
                key: el.campaignId,
                id: (page - 1) * 10 + idx + 1,
              };
            }) || []
          }
          scroll={{ x: "max-content" }}
          pagination={{
            current: page,
            pageSize: 10,
            total: data.count || 0,
            onChange: (page) => {
              setPage(page);
            },
          }}
        />
      </div>
    </CardContainer>
  );
}

export default StockSettingList;
